import { Controller } from '@hotwired/stimulus';

class TriggerController extends Controller {
  openDocsUploadModal() {
    this.dispatch('open.docsFileUploadModal', {
      bubbles: true,
      cancelable: false,
    });
  }
}

export default TriggerController;