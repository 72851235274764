import {Controller} from "@hotwired/stimulus"
import TomSelect from "tom-select"
import {sendTurboStreamRequest} from "../helpers/turbo_stream_helper"
import {buildHeaders, buildRequest, buildUrl} from "../helpers/build_request"

export default class extends Controller {
  static targets = ["select"]

  static values = {
    url: String,
    noResult: String,
    placeholder: String,
    fetch: Boolean,
    objectId: Number,
    addRemoveButton: Boolean,
    checkbox: Boolean,
    turboRequiredParams: Object
  }

  async connect() {
    if (this.checkAndAutoSelectIfRequired instanceof Function) {
      this.checkAndAutoSelectIfRequired = this.checkAndAutoSelectIfRequired.bind(this);
    } else {
      console.error("checkAndAutoSelectIfRequired is not a function: ", this.checkAndAutoSelectIfRequired);
    }
    await this.select();
    this.element.addEventListener('updated', this.checkAndAutoSelectIfRequired);
  }

  async select() {
    if (this._select !== undefined) return
    let selectOptions = this.setupSelectOptions()
    this._select = new TomSelect(this.selectTarget, selectOptions);
  }

  turboStreamRequest() {
    if (this.hasTurboRequiredParams) {
      const params = {id: this.getId(), ...this.turboRequiredParamsValue};
      delete params.url;
      if (params.id !== '' && params.id) {
        const url = buildUrl(this.turboRequiredParamsValue.url, params);
        const request = buildRequest(url, buildHeaders());
        sendTurboStreamRequest(request, this.turboRequiredParamsValue.event);
      }
    }
  }

  getId() {
    return this.selectTarget.tomselect.getValue();
  }

  hasTurboRequiredParams() {
    const turboRequiredParams = this.turboRequiredParamsValue;
    return turboRequiredParams && Object.keys(turboRequiredParams).length > 0;
  }

  setupSelectOptions() {
    let isMultiple = this.isMultiple()
    let addRemoveButton = this.addRemoveButtonValue
    let checkBoxPlugin = this.checkboxValue
    let noResult = this.noResultValue
    let plugins = []
    if (isMultiple || addRemoveButton) plugins.push('remove_button');
    if (checkBoxPlugin) plugins.push('checkbox_options');
    return {
      sortField: {
        field: "text",
        direction: "asc"
      },
      hidePlaceholder: true,
      placeholder: this.placeholderValue,
      valueField: 'value',
      plugins: plugins,
      closeAfterSelect: !isMultiple,
      searchField: 'text',
      render: {
        no_results: function (data, escape) {
          return '<div class="no-results">' + noResult + '</div>';
        }
      },
      load: this.urlValue ? this.load(this.urlValue) : null
    }
  }

  load(filteredUrl) {
    return function (query, callback) {
      const url = buildUrl(filteredUrl, {filter: query})
      fetch(url)
        .then(response => response.json())
        .then(callback)
        .catch(callback);
    }
  }

  isMultiple() {
    return this.selectTarget.getAttribute("multiple") === "multiple"
  }

  checkAndAutoSelectIfRequired() {
    if (this.element.hasAttribute('required')) {
      let items = this._select.items;
      if (items.length === 1) {
        this._select.setValue(items[0]);
      }
    }
  }
}