import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { extendLocalizations } from './tabulator/extendLocalizations';
import { extendFormatters } from './tabulator/extendFormatters';
import { extendSorters } from './tabulator/extendSorters';
import { extendPagination } from './tabulator/extendPagination';

initializeTabulatorExtensions();

function initializeTabulatorExtensions() {
  extendLocalizations();
  extendFormatters();
  extendSorters();
  extendPagination();
}

export default Tabulator;
