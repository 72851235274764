import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["fileInput", "authenticityToken", "progress"]

    static values = {
        model: String,
        url: String
    }

    connect() {
        this.chunkSize = 1024 * 1024; // 1 MB
    }

    upload() {
        this.csrfToken = this.authenticityTokenTarget.value
        const file = this.fileInputTarget.files[0];
        if (this.validate(file)) {
            this.totalChunks = Math.ceil(file.size / this.chunkSize);
            this.currentChunk = 0;
            this.readChunks(file);
        }
    }

    // @todo make proper notifications, not alerts
    validate(file) {
        if (file.size === 0) {
            alert("File is empty");
            return false;
        } else if (file.name !== "Loinc.csv" && this.modelValue == "Loinc") {
            alert("You can only upload Loinc.csv file")
            return false;
        } else if (file.type !== "text/csv") {
            alert("You can only upload .csv file")
            return false;
        }
         else {
            return true;
        }
    }

    readChunks(file) {
        let offset = this.currentChunk * this.chunkSize;
        let chunk = file.slice(offset, offset + this.chunkSize);
        let reader = new FileReader();
        reader.readAsArrayBuffer(chunk);
        reader.onload = (event) => {
            this.uploadChunk(file, event.target.result);
        };
    }

    uploadChunk(file, chunk) {
        let xhr = new XMLHttpRequest();
        let url = this.urlValue;
        xhr.open("POST", url, true);
        xhr.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
                let percentage = ((this.currentChunk + 1) / this.totalChunks) * 100;
                this.progressTarget.value = percentage;
            }
        });
        xhr.setRequestHeader("Content-Type", "application/octet-stream");
        xhr.setRequestHeader("X-Chunk-Number", `${this.currentChunk}`);
        xhr.setRequestHeader("X-CSRF-Token", `${this.csrfToken}`);
        xhr.setRequestHeader("X-Total-Chunks", `${this.totalChunks}`);
        xhr.setRequestHeader("Content-Range", `bytes ${this.currentChunk * this.chunkSize}-${(this.currentChunk + 1) * this.chunkSize - 1}/${file.size}`);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                this.currentChunk++;
                if (this.currentChunk < this.totalChunks) {
                    this.readChunks(file);
                } else {
                    console.log("File upload complete");
                }
            }
        };
        xhr.send(chunk);
    }
}