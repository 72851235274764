import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

// Connects to data-controller="drag"
export default class extends Controller {
  static targets = ["input"]

  static values = {
    type: String
  }

  connect() {
    if (this.typeValue === "order") {
      this.sortableOrder()
    }
    
    if (this.typeValue === "filter") {
      this.sortableDragDrop()
    }

    if (this.typeValue === "headers") {
      this.sortableDragDrop()
    }
  }

  createSortable(options) {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      ghostClass: "ghost",
      ...options,
    })
  }

  sortableOrder() {
    this.createSortable({
      dataIdAttr: "data-id",
      onEnd: this.updateOrders.bind(this),
    })
  }

  sortableDragDrop() {
    this.createSortable({
      group: this.typeValue,
      dataIdAttr: "data-id",
      onMove: this.preventDragMultiple.bind(this),
      onAdd: this.updateElementValue.bind(this),
    })
  }
  
  updateOrders() {
    const items = this.element.children

    for (let item of items) {
      const orderElement = this.inputTargets.find(
        (order) => order.dataset.id === item.dataset.id
      )

      const updatedOrder = this.sortable.toArray().findIndex(
        (parentId) => parentId == orderElement.dataset.id
      )

      orderElement.value = updatedOrder
    }
  }

  preventDragMultiple(e) {
    if (!e.to.dataset.isLimsItemsBox) {
      return !e.to.hasChildNodes()
    }
  }

  updateElementValue(e) {
    const element = this.inputTargets.find(
      (el) => el.dataset.id === e.item.dataset.id
    )
    if (e.to.dataset.isLimsItemsBox) {
      // Remove value attr if dragged back into lims items box
      element.removeAttribute("value");
    }
    else {
      element.value = e.to.dataset.value;
    }
  }
  
  removeDragItem(event) {
    const deleteConfirm = confirm(event.currentTarget.dataset.confirmation);

    if (deleteConfirm) {
      const parentElement = event.currentTarget.parentElement;
      parentElement.remove();

      this.updateInputTargets();
  
      this.updateIntervals();
    }
  }

  updateInputTargets(parentElement) {
    const deletedElement = this.inputTargets.find(input => input.parentElement === parentElement);
    const deletedIndex = this.inputTargets.indexOf(deletedElement);

    // remove from targets
    if (deletedIndex > -1) {
      this.inputTargets.splice(deletedIndex, 1);
    }
    
    this.inputTargets.forEach((input, index) => {
      input.value = index;
    });
  }

  updateIntervals() {
    const intervalDefinitions = document.querySelectorAll('.interval_items');
    const lastInterval = intervalDefinitions[intervalDefinitions.length - 1];
    lastInterval.remove();
  }
}
