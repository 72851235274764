import { TabulatorFull as Tabulator } from 'tabulator-tables';

export function extendSorters() {
  Tabulator.extendModule("sort", "sorters", {
    numberWithHtmlSorter: (a, b, aRow, bRow, column, dir, sorterParams) => {
      let numA = parseFloat(a.value);
      let numB = parseFloat(b.value);

      if (isNaN(numA)) {
        numA = 0;
      }
      else if (isNaN(numB)) {
        numB = 0;
      }

      return numA - numB;
    },
    tableLinkSorter: (a, b, aRow, bRow, column, dir, sorterParams) => {
      return String(a.value).localeCompare(String(b.value));
    }
  });
}
