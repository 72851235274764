import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {

  static values = {
    type: String,
    dataset: Array
  };

  initialize() {
    this.chart = null;
  }

  connect() {
    this[this.typeValue]();
  }

  generateDeviceFaultChart() {
    const data = this.datasetValue;
    const seriesData = data.map(item => Object.values(item)[0]);
    const labelsData = data.map(item => Object.keys(item)[0]);
  
    const isEmpty = seriesData.length === 0;
  
    const options = {
      chart: {
        type: 'pie',
        height: 350
      },
      yaxis: {
        show: !isEmpty,
        labels: {
          formatter: function(val, index) {
            return val.toFixed(0);
          }
        }
      },
      series: isEmpty ? [[]] : seriesData,
      labels: isEmpty ? [''] : labelsData,
      legend: {
        position: 'bottom',
        show: !isEmpty
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom',
            show: !isEmpty
          }
        }
      }]
    };
  
    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }
  
  generateFaultTypeChart() {
    const data = this.datasetValue; 
    const seriesData = data.map(item => Object.values(item)[0]);
    const labelsData = data.map(item => Object.keys(item)[0]);
  
    const options = {
      series: [{
        data: seriesData,
        name: this.element.dataset.serviceCount,
      }],
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          horizontal: true,
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: labelsData
      }
    };
  
    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  generateServiceTypeChart() {
    const data = this.datasetValue;
    const seriesData = data.map(item => Object.values(item)[0]);
    const labelsData = data.map(item => Object.keys(item)[0]);
  
    const isEmpty = seriesData.length === 0;
  
    const options = {
      chart: {
        type: 'pie',
        height: 350
      },
      yaxis: {
        show: !isEmpty,
        labels: {
          formatter: function(val, index) {
            return val.toFixed(0);
          }
        }
      },
      series: isEmpty ? [[]] : seriesData,
      labels: isEmpty ? [''] : labelsData,
      legend: {
        position: 'bottom',
        show: !isEmpty
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom',
            show: !isEmpty
          }
        }
      }]
    };
  
    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  generateMonthlyTrendChart() {
    const data = this.datasetValue;
    const labels = data.map(item => item.month);
    const avgSolutionTimes = data.map(item => item.avg_solution_time);
    const delayedServicesRatio = data.map(item => item.delayed_services_ratio);
  
    const options = {
      series: [{
        name: this.element.dataset.averageSolutionTimes,
        type: 'column',
        data: avgSolutionTimes
      }, 
      {
        name: this.element.dataset.delayedServicesRatio,
        type: 'line',
        data: delayedServicesRatio,
      }],
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        width: [0, 4]
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
        formatter: (val, opts) => {
          return opts.seriesIndex === 1 ? val + "%" : val + ` ${this.element.dataset.min}`;
        }
      },
      labels: labels,
      yaxis: [{
        title: {
          text: this.element.dataset.averageSolutionTimes,
          style: {
            cssClass: "apexcharts-custom-title-default-blue"
          }
        },
      }, {
        opposite: true,
        title: {
          text: this.element.dataset.delayedServicesRatio,
          style: {
            cssClass: "apexcharts-custom-title-default-green"
          }
        },
        labels: {
          formatter: (val) => {
            return val + "%";
          }
        }
      }],
      tooltip: {
        y: [{
          formatter: (val) => {
            return val + ` ${this.element.dataset.min}`;
          }
        }, {
          formatter: (val) => {
            return val + "%";
          }
        }]
      }
    };
    
    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  generateServicePerformanceChart() {
    const data = this.datasetValue;
  
    const labels = data.map(item => item.brand_name);
    const avgSolutionTimes = data.map(item => item.avg_solution_time);
    const delayedServices = data.map(item => item.delayed_services);
    const onTimeServices = data.map(item => item.on_time_services);
  
    const options = {
      series: [{
        name: this.element.dataset.averageSolutionTimes,
        type: 'column',
        data: avgSolutionTimes
      }, {
        name: this.element.dataset.delayedServiceCount,
        type: 'column',
        data: delayedServices,
        stack: 'services'
      }, {
        name: this.element.dataset.onTimeServiceCount,
        type: 'column',
        data: onTimeServices,
        stack: 'services'
      }],
      chart: {
        height: 350,
        type: 'line',
        stacked: true 
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [1, 1, 1]
      },
      xaxis: {
        categories: labels,
      },
      yaxis: [{
          seriesName: this.element.dataset.averageSolutionTimes,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: this.element.dataset.averageSolutionTimes,
            style: {
              cssClass: "apexcharts-custom-title-default-blue"
            }
          }
        },
        {
          seriesName: this.element.dataset.serviceCount,
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: this.element.dataset.serviceCount,
            style: {
              cssClass: "apexcharts-custom-title-default-green"
            }
          },
        },
      ],
      colors: ['#008FFB', '#FF4560', '#00E396'],
      tooltip: {
        y: [{
          formatter: (value) => {
            return value + ` ${this.element.dataset.min}`;
          }
        }],
        fixed: {
          offsetY: 30,
          offsetX: 60
        },
      },
      legend: {
        horizontalAlign: 'center',
        offsetX: 40
      }
    };
  
    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }
  
  disconnect() {
    if (this.chart) {
      this.chart.destroy(); 
      this.chart = null;
    }
  }
}
