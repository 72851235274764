import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="test-master"
export default class extends Controller {
  static targets = [
    "labGroup",
    "testGroup"
  ];

  connect() {
    if (typeof this.testGroupTarget.tomselect === "undefined") {
      setTimeout(() => {
        this.connect();
      }, 100);
      } 
    else {
      this.labGroupTarget.dispatchEvent(new Event('change'));
    }
  }

  updateStateOfTestGroup() {
    const testGroup = this.testGroupTarget.tomselect;
    if (this.labGroupTarget.value === '') {
      testGroup.disable();
    }
    else {
      testGroup.enable();
    }
  }
}
