import {Controller} from "@hotwired/stimulus"
import ApexCharts from "apexcharts";

// Connects to data-controller="consumption-analysis"
export default class extends Controller {

  static values = {
    chartOptions: String
  };

  connect() {
    super.connect();
    this.getLastOneYearChartOptions();
  }

  getLastOneYearChartOptions() {
    const data = JSON.parse(this.chartOptionsValue);
    this.initializeLastOneYearChart(data)
  }

  initializeLastOneYearChart(data) {
    const months = data.data.map((item) => item.month);
    const consumption = data.data.map((item) => item.consumption);
    const chartTitle = data.chart_title;

    const options = {
      series: [
        {
          name: chartTitle,
          data: consumption,
        },
      ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: months,
      },
    };

    const chart = new ApexCharts(document.querySelector("#oneYearConsumptionChart"), options);
    chart.render();
  }


  disconnect() {
    super.disconnect();
  }
}
