/**
 * Extracts the value of a specified parameter from a given URL.
 * @param {string} urlString - The URL string to parse.
 * @param {string} paramName - The name of the parameter to extract the value for.
 * @returns {string|null} The value of the parameter, or null if not found.
 */
export function getParamValueFromUrl(urlString, paramName) {
  const url = new URL(urlString);
  const params = new URLSearchParams(url.search);
  return params.get(paramName);
}

// Example usage:
// const urlString = "http://localhost:3000/tr/update_warehouse_demand_stock_card_detail?id=4&identifier=1718615669384&event=focus_wareDetailDemandQuantityTargets";

// Get the value of a single parameter
// const identifierValue = getParamValueFromUrl(urlString, 'identifier');
// console.log(identifierValue); // Outputs: 1718615669384