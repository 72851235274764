import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import { downloadPdf } from "../helpers/download_pdf"

export default class extends Controller {
  static targets = [
    "supplierSelect",
    "trainingInstitution",
    'pdfButton'
  ];

  connect() {
    setTimeout(() => {
      this.initializeSelect();
      this.checkAndSetTrainingInstitution();
    });
  }

  initializeSelect() {
    const selectOptions = {
      sortField: {
        field: "text",
        direction: "asc",
      },
      placeholder: this.supplierSelectTarget.dataset.educationPlaceholderValue,
      noResult: this.supplierSelectTarget.dataset.educationNoResultValue,
      create: (input) => {
        this.trainingInstitutionTarget.value = input;
        this.supplierSelectTarget.setAttribute("disabled", "true");
        return {
          value: input,
          text: input,
        };
      },
      onChange: (value) => {
        if (!isNaN(value) && value.trim() !== "") {
          this.trainingInstitutionTarget.value = "";
        } else {
          this.supplierSelectTarget.removeAttribute("disabled");
        }
      },
    };

    this._select = new TomSelect(this.supplierSelectTarget, selectOptions);
  }

  checkAndSetTrainingInstitution() {
    const trainingInstitutionValue = this.trainingInstitutionTarget.value;
    if (
      trainingInstitutionValue &&
      !this.optionExists(trainingInstitutionValue)
    ) {
      this.addCustomOptionIfNeeded(trainingInstitutionValue);
      this._select.setValue(trainingInstitutionValue);
    }
  }

  addCustomOptionIfNeeded(trainingInstitutionValue) {
    if (
      trainingInstitutionValue &&
      !this.optionExists(trainingInstitutionValue)
    ) {
      this._select.addOption({
        value: trainingInstitutionValue,
        text: trainingInstitutionValue,
      });
      this._select.addItem(trainingInstitutionValue, true);
    }
  }

  optionExists(value) {
    return this._select.options[value] !== undefined;
  }

  toggleInput(event) {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute(
      "data-education-target-id"
    );
    const input = document.getElementById(targetId);
    if (input) {
      input.disabled = !input.disabled;
    }
  }

  toggleAttendantInputs(event) {
    event.preventDefault();
    const formWrapper = event.target.closest(".nested-form-wrapper");

    const inputs = formWrapper.querySelectorAll("input, select, textarea");

    inputs.forEach((input) => {
      if (input.tagName.toLowerCase() === "select") {
        // if input is tomselect
        const tomSelectInstance = input.tomselect;
        if (tomSelectInstance) {
          if (input.disabled) {
            tomSelectInstance.enable();
            tomSelectInstance.unlock();
          } else {
            tomSelectInstance.disable();
            tomSelectInstance.lock();
          }
        }
      } else {
        // html inputs
        input.disabled = !input.disabled;
      }
    });
  }

  // downloand pdf
  pdfButtonTargetConnected() {
    this.pdfButtonTarget.addEventListener('click',(event) => downloadPdf(event));
  }
}
