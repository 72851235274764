import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  
  static values = {
    text: String,
    variant: String,
    position: String,
    duration: Number
  };

  get text() {
    return this.textValue || '';
  }

  get variant() {
    return this.variantValue || "light";
  }

  get position() {
    return this.positionValue || "right-bottom";
  }

  get duration() {
    return this.durationValue || 2000;
  }

  initialize() {
    $notification({ text: this.text, variant: this.variant, position: this.position, duration: this.duration });
  }
}
