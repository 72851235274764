import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="device-card-transfer"
export default class extends Controller {
  static targets = [
    'lab',
    'consumptionPoint',
  ]

  connect() {
    setTimeout(() => {
      this.labTarget.dispatchEvent(new Event('change'));
    }, 0);
  }
    
  updateConsumptionPointState() {
    let consumptionPoint = this.consumptionPointTarget.tomselect;
    if (this.labTarget.value === '') {
      consumptionPoint.disable();
    }
    else {
      consumptionPoint.enable();
    }
  }
}
