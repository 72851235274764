import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tree"];

  initialize() {
    this.treeTargets.forEach(target => {
      const start = target.dataset.start === "true";
      const showMultiple = start ? [0] : true;

      this.initializeAccordion(target, 200, showMultiple);
    });
  }

  initializeAccordion(target, duration, showMultiple) {
    new Accordion(target, {
      duration: duration,
      showMultiple
    });
  }
}
