// data-controller='form-redirect'
import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  next(submitEndEvent) {
    let fetchResponse = submitEndEvent.detail.fetchResponse;
    document.addEventListener("turbo:frame-missing", (event) => {
      event.preventDefault();
      if (submitEndEvent.detail.success) {
        window.location.href = fetchResponse.response.url;
      }
    });

    // TODO: :Added redirect because of an issue when trying to navigate with Turbo
    // After successful save response redirection will be true
    if (fetchResponse.response.redirected) {
      window.location.href = fetchResponse.response.url
    }
  }
}