import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="company"
export default class extends Controller {
  static targets = ["turboFrameTag"];

  initiateTurboFrame(e) {
    if (e.target.value) {
      this.turboFrameTagTarget.src = e.target.dataset.url.replace(":id", e.target.value);
    }
    else {
      this.turboFrameTagTarget.src = '';
    }
  }
}

