// app/javascript/helpers/tabulator_helpers.js
export function handleDeleteClick(dataset, tabulatorInstance) {
  const url = dataset.deleteUrl;
  const rowId = dataset.rowId;
  const confirmMessage = dataset.confirm;

  if (confirm(confirmMessage)) {
    deleteRow(url, rowId, tabulatorInstance);
  }
}

export async function deleteRow(url, rowId, tabulatorInstance) {
  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    tabulatorInstance.deleteRow(rowId);
  } catch (error) {
    console.error("Failed to delete the row:", error);
  }
}
