import { Controller } from "@hotwired/stimulus";
import pako from "pako";

// Connects to data-controller="stocktracking-form"
export default class extends Controller {
  static targets = ["compressedDataInput", "uncompressedAttributes", "quantityInput"];

  connect() {
    this.element.addEventListener("submit", this.compressData.bind(this));
  }

  compressData(event) {
    const formData = new FormData(this.element);
    const filteredData = this.extractStocktrackingDetails(formData);
    const compressedString = this.compressToJsonString(filteredData);

    this.compressedDataInputTarget.value = compressedString;
    this.toggleAttributeInputs(true);
  }

  toggleAttributeInputs(disabled = false) {
    this.uncompressedAttributesTarget.querySelectorAll("input").forEach((input) => {
      input.disabled = disabled;
    });
  }

  extractStocktrackingDetails(formData) {
    return Object.fromEntries(
      Array.from(formData.entries()).filter(([key]) =>
        key.startsWith("stocktracking[stocktracking_details_attributes]")
      )
    );
  }

  compressToJsonString(data) {
    const jsonString = JSON.stringify(data);
    const compressed = pako.deflate(jsonString);
    return Array.from(compressed).join(",");
  }

  resetQuantityInput(event) {
    if (confirm(event.currentTarget.dataset.resetConfirm)) {
      this.quantityInputTargets.forEach(target => {
        target.value = 0;
      });
    }
  }
}
