import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["info", "input"];

  connect() {
    super.connect();
  }

  upload(event) {
    const files = event.target.files;
    this.updateFileInfo(files);
  }

  updateFileInfo(files) {
    const dataTransfer = new DataTransfer();
    let totalSize = 0;

    // add previously added files again
    if (this.inputTarget.files.length) {
      for (const file of this.inputTarget.files) {
        totalSize += file.size;
        dataTransfer.items.add(file);
      }
    }

    // add new files
    for (let file of files) {
      totalSize += file.size;
      if (totalSize > 10 * 1024 * 1024) {
        this.showNotification(this.element.dataset.fileMaxFilesizeErrorMsg, "error");
        return;
      } else {
        dataTransfer.items.add(file);
      }
    }

    this.inputTarget.files = dataTransfer.files;
    this.infoTarget.innerHTML = "";

    const filesArray = Array.from(this.inputTarget.files);
    if (this.inputTarget.hasAttribute('multiple')) {
      // multiple files - show icon
      filesArray.forEach((file, index) => {
        const fileDiv = document.createElement("div");
        fileDiv.className = "attachment-wrapper custom-tooltip";
        fileDiv.innerHTML = `
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"></path>
          </svg>
        </span>
        <span class="custom-tooltiptext">${file.name}</span>
        <button class="attachment-delete-file-btn" data-index="${index}" type="button">X</button>`;
        this.infoTarget.appendChild(fileDiv);
      });
    } else {
      // single file
      const fileNameText = document.createTextNode(filesArray[0].name);
      this.infoTarget.appendChild(fileNameText);
    }

    this.infoTarget.querySelectorAll('.attachment-delete-file-btn').forEach(button => {
      button.addEventListener('click', (event) => {
        event.preventDefault();
        const fileIndex = parseInt(event.target.getAttribute('data-index'), 10);
        this.removeFile(fileIndex);
      });
    });
  }

  removeFile(index) {
    const newFiles = [...this.inputTarget.files].filter((_, i) => i !== index);
    const dataTransfer = new DataTransfer();
    newFiles.forEach(file => dataTransfer.items.add(file));
    this.inputTarget.files = dataTransfer.files;
    this.updateFileInfo([]);
  }

  showNotification(text, variant) {
    $notification({
      text: text,
      variant: variant,
    });
  }
}
