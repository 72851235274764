import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="stocktracking"
export default class extends Controller {
  static targets = ["identifier"];

  connect() {
    if (
      this.hasIdentifierTarget &&
      typeof this.identifierTarget.tomselect === "undefined"
    ) {
      setTimeout(() => {
        this.connect();
      }, 100);
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    const selectedIdentifier =
      this.identifierTarget.tomselect.getValue();

    if (selectedIdentifier != "") {
      this.identifierTarget.form.submit();
    }
  }
}
