import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form"
export default class extends Controller {
  static values = {
    excludeSelector: String
  }

  connect() {
    this.enableElements();
    this.addTurboEventListeners();
  }

  addTurboEventListeners() {
    this.element.addEventListener("turbo:submit-end", this.handleTurboSubmit.bind(this));
  }

  handleTurboSubmit(event) {
    if (event.detail.success) {
      this.disableElements();
    } else {
      this.enableElements();
    }
  }

  blockMultipleSubmission() {
    this.disableElements();
  }
  
  disableElements() {
    this.getFormElements().forEach(element => {
      element.setAttribute('disabled', 'disabled');
    });
  }

  enableElements() {
    this.getFormElements().forEach(element => {
      element.removeAttribute('disabled');
    });
  }

  getFormElements() {
    const allElements = this.element.querySelectorAll('a, button, input[type=button], input[type=submit]');
    return Array.from(allElements).filter(element => !this.shouldExclude(element));
  }

  shouldExclude(element) {
    return this.hasExcludeSelectorValue && element.matches(this.excludeSelectorValue);
  }
}