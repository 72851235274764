import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="submit"
export default class extends Controller {

  submitForm(){
    this.element.dispatchEvent(new Event("submit", { bubbles: true }));
    this.element.submit();
  }
}
