import { Controller } from "@hotwired/stimulus"
import Tabulator from "../../extensions/TabulatorExtended";
import { editSvg } from "../../helpers/constants";

// Connects to data-controller="tabulator--warehouses"
export default class extends Controller {
  static values = {
    url: String,
    columns: Array,
    locale: String,
    editUrlTemplate: String
  }

  connect() {
    const columns = this.prepareColumns(this.columnsValue);
    this.tabulator = new Tabulator(this.element, this.tabulatorOptions(columns));
  }

  prepareColumns(columns) {
    columns.push(this.editColumn());
    return columns;
  }

  tabulatorOptions(columns) {
    return {
      ajaxURL: this.urlValue,
      ajaxConfig: "GET",
      layout: "fitColumns",
      pagination: true,
      paginationMode: 'remote',
      paginationSize: 15,
      paginationSizeSelector: [15, 25, 50, 100],
      sortMode: 'remote',
      columns: columns,
      locale: this.localeValue,
      dataLoaderLoading: "<div class='tabulator-spinner'></div>",
      paginationCounter: "pageCounter",
    };
  }

  editColumn() {
    return {
      title: "",
      field: "actions",
      formatter: this.editColumnFormatter.bind(this),
      width: 50,
      headerSort: false,
    };
  }

  editColumnFormatter(cell) {
    const row = cell.getRow();
    const data = row.getData();
    const editUrl = this.editUrl(data);

    return `
      <div class='flex items-center space-x-1'>
        <a href="${editUrl}" data-turbo="false" class="edit-button">${editSvg}</a>
      </div>
    `;
  }

  editUrl(data) {
    return this.editUrlTemplateValue.replace(":id", data.id);
  }

  disconnect() {
    if (this.tabulator) {
      this.tabulator.destroy();
      this.tabulator = null;
    }
  }
}
