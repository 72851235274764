import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static values = {
    placement: String,
  };

  get placement() {
    return this.placementValue || 'bottom-start';
  }

  get config() {
    return {
        placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 4],
            },
          },
        ],
      };
  }

  initialize() {
    this.popper = new Popper(this.element, ".popper-ref", ".popper-root", this.config);
  }

  close() {
    this.popper.box.classList.remove('show');
    this.popper.ref.classList.remove('is-active');
  }
}
