import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["labBox", "labGroupBox", "placeHere"];
  static values = {
    listStaffResourcesUrl: String
  }

  connect() {
    super.connect();

    setTimeout(() => {
      if (this.labBoxTarget.tomselect.getValue() && this.labGroupBoxTarget.tomselect.getValue()) {
        this.takeUserList();
      }
    });
  }

  get selectedUserId() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("user_id");
  }

  async takeUserList() {
    const labId = this.labBoxTarget.tomselect.getValue();
    const labGroupId = this.labGroupBoxTarget.tomselect.getValue();

    if (labId !== "" && labGroupId !== "") {
      const url = new URL(this.listStaffResourcesUrlValue);
      url.searchParams.append('id', labId);
      url.searchParams.append('resource_id', labGroupId);
      url.searchParams.append('filter_resource_type', 'lab_group');

      const response = await fetch(url);
      const data = await response.json();
      this.insertTheUserList(data["resources"]);
    }
  }

  insertTheUserList(users) {
    const placeHere = this.placeHereTarget;
    const existingUserIds = new Set(Array.from(placeHere.children).map((li) => li.dataset.userId));
    const selectedUserId = this.selectedUserId;
    const template = document.getElementById("user-li-template");

    users.forEach((user) => {
      if (!existingUserIds.has(user.id.toString())) {
        const newLi = template.content.cloneNode(true).querySelector("li");
        const checkbox = newLi.querySelector('input[type="checkbox"]');
        const label = newLi.querySelector("label");

        newLi.dataset.userId = user.id;
        checkbox.value = user.id;
        checkbox.disabled = false;
        checkbox.checked = user.id.toString() === selectedUserId;
        label.textContent = user.name;

        placeHere.appendChild(newLi);
      }
    });

    // Remove users that are no longer in the list
    Array.from(placeHere.children).forEach((li) => {
      if (!users.some((user) => user.id.toString() === li.dataset.userId)) {
        li.remove();
      }
    });
  }
}
