import {Controller} from "@hotwired/stimulus"
import Tabulator from "../../extensions/TabulatorExtended";

// Connects to data-controller="tabulator--efficiency-analysis"
export default class extends Controller {
  static values = {
    url: String,
    columns: Array,
    locale: String
  }

  connect() {
    this.tabulator = new Tabulator(this.element, {
      ajaxURL: this.urlValue,
      ajaxConfig: "GET",
      layout: "fitData",
      pagination: true,
      paginationMode: 'local',
      paginationSize: 50,
      paginationSizeSelector: [15, 25, 50, 100],
      sortMode: 'local',
      columns: this.columnsValue,
      locale: this.localeValue,
      dataLoaderLoading: "<div class='tabulator-spinner'></div>",
      paginationCounter: "pageCounter",
    });
  }

  disconnect() {
    if (this.tabulator) {
      this.tabulator.destroy();
      this.tabulator = null;
    }
  }
}
