import {Controller} from "@hotwired/stimulus"
import {sendTurboStreamRequest} from "../helpers/turbo_stream_helper"
import {buildHeaders, buildRequest, buildUrl} from "../helpers/build_request"

// Connects to data-controller="stocktracking-detail"
export default class extends Controller {

  populateStocktrackingDetails(event) {
    const selectBox = event.target;
    const turboParams = JSON.parse(selectBox.dataset.turboParams);
    const params = { stock_card_id: selectBox.value, child_index: turboParams.child_index };
    const url = buildUrl(turboParams.url, params);
    const request = buildRequest(url, buildHeaders());
    sendTurboStreamRequest(request);
  }

  openQrOverlay(event) {
    const qrElement = document.getElementById("scan_barcode");
    qrElement.dispatchEvent(new Event("click"));
  }
}
