import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["warning", "email", "name", "title", "phone", "note"];

  connect() {
    if (this.element.dataset.formType == "create") {
      this.checkUserExist();
    }
  }

  checkUserExist() {
    const email = this.emailTarget.value;
    if (email != "") {
      fetch(`/external_user_exist?email=${email}`)
      .then(response => response.json())
      .then(data => {
        if (data == true) {
          this.showElement(this.warningTarget, this.element.dataset.warning);
          this.disableElements(this.nameTarget, this.titleTarget, this.noteTarget, this.phoneTarget);
        } 
        else {
          this.enableElements(this.nameTarget, this.titleTarget, this.noteTarget, this.phoneTarget);
          this.hideElement(this.warningTarget);
        }
      });
    } 
    else {
      this.hideElement(this.warningTarget);
    }
  }

  disableElements(...elements) {
    elements.forEach(element => element.disabled = true);
  }

  enableElements(...elements) {
    elements.forEach(element => element.disabled = false);
  }

  showElement(element, innerHTML = null) {
    if (element.classList.contains("hidden")) {
      element.classList.remove("hidden");
    }
    if (innerHTML) {
      element.innerHTML = innerHTML;
    }
  }

  hideElement(element) {
    if (!element.classList.contains("hidden")) {
      element.classList.add("hidden");
    }
  }
}