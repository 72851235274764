import { Controller } from "@hotwired/stimulus"
import { downloadPdf } from "../helpers/download_pdf";
import TomSelect from "tom-select"

// Connects to data-controller="warehouse-stock"
export default class extends Controller {
  static targets = [
    "stockCard",
    "unit",
    "quantity",
    "lot",
    "expireDate",
    "errorField",
    "quantityErrorField",
    'pdfButton'
  ];

  validateStockStatus() {
    let stockCardId = this.stockCardTarget.value;
    let unit = this.unitTarget.value;
    let quantity = this.quantityTarget.value;
    let lot = this.lotTarget.value;
    let expireDate = this.expireDateTarget.value;
    let warehouseId = document.getElementById("warehouse-input").value;

    fetch(`/stock_status_validation?stock_card_id=${stockCardId}&unit=${unit}&quantity=${quantity}&lot=${lot}&expire_date=${expireDate}&warehouse_id=${warehouseId}`)
      .then(response => response.json())
      .then(data => this.updateErrors(data))
  }

  updateErrors(data) {
    const submitInput = document.getElementById("submit-input");

    if (data["status"] == "true") {
      this.noError(submitInput);
    }
    else if (data["status"] == "error") {
      this.setErrorMessage(data['message'], this.quantityErrorFieldTarget);
      this.hasError(submitInput, this.quantityErrorFieldTarget);
    }
    else if (data["status"] == "false") {
      this.setErrorMessage(data['message'], this.errorFieldTarget);
      this.hasError(submitInput, this.errorFieldTarget);
    }

    this.checkErrorFields() ? submitInput.disabled = false : submitInput.disabled = true ;

  }

  checkErrorFields() {
    const fieldHaveHidden = (errorField) => errorField.classList.contains("hidden");
    const errorFields = Array.from(document.querySelectorAll('[id="warehouseStockErrorField"]'));
    return errorFields.every(fieldHaveHidden);
  }

  hasError(submitInput, errorTarget) {
    this.noError(submitInput);
    submitInput.disabled = true;
    errorTarget.classList.remove("hidden");
  }

  noError(submitInput) {
    submitInput.disabled = false;
    this.errorFieldTarget.classList.add("hidden");
    this.quantityErrorFieldTarget.classList.add("hidden");
  }

  setErrorMessage(errorMessage, errorTarget) {
    errorTarget.textContent = errorMessage;
  }

  // downloand pdf
  pdfButtonTargetConnected() {
    this.pdfButtonTarget.addEventListener('click',(event) => downloadPdf(event));
  }
}
