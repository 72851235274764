/**
Builds a Request object with the specified URL and headers.
@param {string} url - The URL for the request.
@param {Headers} headers - The headers for the request.
@returns {Request} The built Request object.
*/
export function buildRequest(url, headers) {
  return new Request(url, {headers: headers});
}

/**
Builds the headers for a request.
@param {Object} args - The key-value pairs of headers to be appended.
@param {boolean} isTurboStream - Whether to include the "Accept" header for Turbo Streams.
@returns {Headers} The built Headers object.
*/ 
export function buildHeaders(args = {}, isTurboStream = true) {
  const headers = new Headers();
  if (isTurboStream) {
    headers.append("Accept", "text/vnd.turbo-stream.html");
  }
  Object.entries(args).forEach(([key, value]) => {
    headers.append(key, value);
  });
  return headers;
}

/**
Builds a modified URL with the specified URL and query parameters.
@param {string} url - The URL to modify.
@param {Object} args - The key-value pairs of query parameters to append.
@returns {URL} The modified URL object. 
*/
export function buildUrl(url, args) {
  const modifiedUrl = new URL(url);
  Object.entries(args).forEach(([key, value]) => {
    modifiedUrl.searchParams.append(key, value);
  });
  return modifiedUrl;
}