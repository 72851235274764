/**
Sends a Turbo Stream request and renders the response.
@param {Request} request - The request to send.
@param {string} eventName - The name of the custom event to dispatch after rendering the Turbo Stream message. Optional.
@returns {Promise} A promise that resolves when the Turbo Stream message is rendered.
*/ 
export function sendTurboStreamRequest(request, eventName) {
  return fetch(request)
  .then(response => response.text())
  .then(html => {
    Turbo.renderStreamMessage(html);

    if (eventName) {
      document.dispatchEvent(new CustomEvent(eventName, { detail: { request } }));
    }
  })
  .catch(error => console.error('Error fetching Turbo Stream:', error));
}

// Example usage:
// import { sendTurboStreamRequest } from "../helpers/turbo_stream_helper"
// import { buildRequest, buildUrl, buildHeaders } from "../helpers/build_request"

// Send the turbo_stream_request

// const url = buildUrl(this.turboRequiredParamsValue.url, params);
// const request = buildRequest(url, buildHeaders());
// sendTurboStreamRequest(request, this.turboRequiredParamsValue.event);