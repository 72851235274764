import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["templateElement"];
  static values = {
    turboUrl: String,
    turboRequest: Boolean,
  };

  handleFileSelect(event) {
    if (this.isValidFile(event.target.value)) {
      if (this.turboRequestValue) {
        event.preventDefault();
        this.sendTurboRequest(event.target.files[0]);
      } else {
        this.element.dispatchEvent(new Event("submit", { bubbles: true }));
        this.element.submit();
      }
    } else {
      event.preventDefault();
      $notification({ text: event.target.dataset.invalid, variant: 'error' });
    }
  }
  
  isValidFile(inputValue) {
    const allowedExtensions = /(\.xls|\.xlsx)$/i;
    return allowedExtensions.exec(inputValue);
  }

  async sendTurboRequest(file) {
    const formData = new FormData();
    formData.append("import_stock_file", file);
    formData.append("direct_transfer", true);

    const response = await fetch(this.turboUrlValue, {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (response.ok) {
      const html = await response.text();
      Turbo.renderStreamMessage(html);

      this.checkAndRemoveEmptyRows();
      const event = new CustomEvent("closeModal", {
        bubbles: true,
        cancelable: true,
      });
      document.dispatchEvent(event);
    }
  }

  checkAndRemoveEmptyRows() {
    this.templateElementTargets.forEach(row => {
      const select = row.querySelector('select[data-warehouse-direct-transfer-target="stockCard"]');
      if (select && select.value === '') {
        const deleteButton = row.querySelector('button[data-action="nested-form#remove"]');
        if (deleteButton) {
          deleteButton.click();
        }
      }
    });
  }
}
