import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  initialize() {
    this.Tab = new Tab(this.element);

    if (this.element.dataset.formTab == "true") {
      this.checkValidationErrorsForTabs();
    }
    if (this.element.dataset.urlTab == "true") {
      this.setActiveTabFromUrl();
    }
  }

  checkValidationErrorsForTabs() {
    const tabArr = Array.from(this.Tab.tabs);
    const tabContentsArr = Array.from(this.Tab.tabContents);
    const hasErrorTabsLength = tabArr.filter(tab => tab.dataset.hasError == "true").length;

    this.Tab.tabs.forEach(tab => {
      if (tab.dataset.hasError != "" && tab.dataset.hasError == "true") {
        tab.insertAdjacentHTML("beforeend", 
          `
          <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-warning ml-2" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path>
          </svg>
          `
        );

        if (hasErrorTabsLength == 1) {
          tabArr[0].classList.replace("tab-button-active", "tab-button-default");
          tabArr[0].classList.remove("is-active");

          tabContentsArr[0].classList.remove("is-active");

          tab.classList.replace("tab-button-default", "tab-button-active");
          tab.classList.add("is-active");
          tabContentsArr.filter(content => "#" + content.id == tab.dataset.target)[0].classList.add("is-active");

          this.Tab.aciveTab = tab.dataset.target;
        }
      }
    });
  }

  setActiveTabFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  const tabName = urlParams.get('tab');

  if (tabName) {
    const tabArr = Array.from(this.Tab.tabs);
    const tabContentsArr = Array.from(this.Tab.tabContents);

    tabArr.forEach(tab => {
      tab.classList.replace("tab-button-active", "tab-button-default");
      tab.classList.remove("is-active");
    });
    tabContentsArr.forEach(content => content.classList.remove("is-active"));

    const targetTab = tabArr.find(tab => tab.dataset.target === `#${tabName}`);
    if (targetTab) {
      targetTab.classList.replace("tab-button-default", "tab-button-active");
      targetTab.classList.add("is-active");
      targetTab.click();
      const targetContent = tabContentsArr.find(content => "#" + content.id == targetTab.dataset.target);
      if (targetContent) {
        targetContent.classList.add("is-active");
      }

      this.Tab.activeTab = targetTab.dataset.target;
    }
  }
}
}
