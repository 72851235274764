import { Controller } from "@hotwired/stimulus"
import { buildUrl } from "../helpers/build_request";

export default class extends Controller {
  static values = {
    frameId: String,
    url: String
  }

  selectTest(event) {
    let filterFormParams = this.filterFormParams();
    const url = buildUrl(this.urlValue, {});
    filterFormParams.append('selected_test_id', event.currentTarget.dataset.testId);
    url.search = filterFormParams;
    this.turboVisitReplace(url, this.frameIdValue);
  }

  filterFormParams() {
    return new URLSearchParams(new FormData(document.querySelector('#filter-form')));
  }

  turboVisitReplace(url, frameId) {
    Turbo.visit(url, {
        action: "replace",
        frame: frameId
      }
    )
  }
}
