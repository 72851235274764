/**
 * Downloads a PDF file from the provided URL when the download button is clicked.
 * Main goal is preventing multiple clicks on the download button via pointer events.
 *
 * @param {Event} event - The click event triggered by the download button.
 * @returns {void}
 */
export function downloadPdf(event) {
  event.preventDefault();

  // animations start
  animationState(event, 'start');

  fetch(event.target.href)
  .then(response => {
    if (!response.ok) {
      animationState(event, 'finish');
      throw new Error('Network response was not ok');
    }
    return response.blob();
  })
  .then(blob => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${event.target.dataset.fileName}.pdf`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  })
  .then(() => {
    // animations end
    animationState(event, 'finish');
  })
  .catch(error => console.error('Error:', error));
}

export async function printPdf(event) {
  event.preventDefault();

  // animations start
  animationState(event, 'start');

  try {
    const response = await fetch(event.target.href);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    const printWindow = window.open(data.pdf_url, '_blank');

    if (printWindow) {
      printWindow.addEventListener('load', function() {
        printWindow.print();
      });
    } else {
      alert('Please disable pop-up blocker and try again.');
    }
  } catch (error) {
    console.error('Error:', error);
    alert('Error: PDF printing failed. Please try again.');
  } finally {
    // animations end
    animationState(event, 'finish');
  }
}

/**
 * Updates the animation state of a target element based on the provided state.
 *
 * @param {Event} event - The click event triggered by the download button and passed this function.
 * @param {string} state - The state to set the animation to. Values are 'start' 'finish'.
 * @returns {void}
 */
function animationState(event, state) {
  const downloadSvg = event.target.querySelector(".download-svg");
  const spinnerSvg = event.target.querySelector(".spinner-svg");

  if (state == 'start') {
    event.target.classList.add('pointer-events-none');
    downloadSvg.classList.add('hidden');
    spinnerSvg.classList.remove('hidden');
    spinnerSvg.classList.add('animate-spin');
  } else {
    downloadSvg.classList.remove('hidden');
    spinnerSvg.classList.add('hidden');
    spinnerSvg.classList.remove('animate-spin');
    event.target.classList.remove('pointer-events-none');
  }
}

