import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar"
export default class extends Controller {
  static targets = ["fields", "header"]

  updateSidebar(event) {
    const barName = event.currentTarget.dataset.sidebarValue;
    const barHeader = event.currentTarget.dataset.sidebarHeader;

    this.fieldsTargets.forEach(field => {
      field.classList.add("hidden");
    });

    const subBarElement = this.fieldsTargets.filter(element => element.id === barName);
    this.headerTarget.innerHTML = barHeader;
    subBarElement[0].classList.remove("hidden");
    document.body.classList.add("is-sidebar-open");
  }
}
