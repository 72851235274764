import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {
  
  static values = {
    type: String
  };

  connect() {
    if (this.typeValue == 'summaryPieChart') {
      this.createChart(this.radialChart([this.element.dataset.periodCompletion, this.element.dataset.targetCompletion],
                                        ["Period", "Target"],
                                        ["#4ade80", "#f43f5e"]));
    }

    if (this.typeValue == 'lineChart') {
      this.createChart(this.lineChart());
    }
  }

  createChart(chartOptions) {
    setTimeout(() => {
      this.element._chart = new ApexCharts(this.element, chartOptions);
      this.element._chart.render();
    });
  }

  radialChart(series, labels, colors) {
    return {
      colors: colors,
      series: series,
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 15,
            size: "35%",
          },
          track: {
            margin: 15,
          },
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                return Math.round(w.config.series.reduce((s, v) => s + v));
              },
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: labels,
    };
  }

  lineChart() {
    return {
      colors: ["#a855f7"],
      series: [
        {
          name: "Sales",
          data: [14, 13, 10, 9, 19, 22, 25],
        },
      ],
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#1E202C",
          top: 18,
          left: 6,
          blur: 8,
          opacity: 0.1,
        },
      },
      stroke: {
        width: 8,
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: [
          "1/11/2000",
          "2/11/2000",
          "3/11/2000",
          "4/11/2000",
          "5/11/2000",
          "6/11/2000",
          "7/11/2000",
        ],
        tickAmount: 10,
        labels: {
          formatter: function (value, timestamp, opts) {
            return opts.dateFormatter(new Date(timestamp), "dd MMM");
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["#86efac"],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 0.95,
          stops: [0, 100, 0, 100],
        },
      },
      yaxis: {
        min: -10,
        max: 40,
      },
    };
  }
}
