import {Controller} from "@hotwired/stimulus";
import {sendTurboStreamRequest} from "../helpers/turbo_stream_helper";
import {buildHeaders, buildRequest, buildUrl} from "../helpers/build_request";

// Connects to data-controller="stock-card"
export default class extends Controller {
  static targets = ["testMaster", "testGroup", "labGroup", "stockMainGroup", "stockSubGroup", "testMasterToggleInput"];

  connect() {
    setTimeout(() => {
      this.testGroupTarget.dispatchEvent(new Event("change"));
    });
  }

  initialize() {
    this.testMasterToggle();

    setTimeout(() => this.setInitialStockSubGroupStatus(false));
  }

  setInitialStockSubGroupStatus(value) {
    this.toggleTomSelectElement(this.stockSubGroupTarget.tomselect, value);
  }

  activateStockSubGroupStatus() {
    this.toggleTomSelectElement(this.stockSubGroupTarget.tomselect, true);
  }

  testMasterToggle() {
    const testMaster = this.testMasterTarget.tomselect;
    const labGroup = this.labGroupTarget.tomselect;
    const testGroup = this.testGroupTarget.tomselect;

    const isTestMasterDisabled = this.testMasterToggleInputTarget.checked;

    if (typeof testMaster === "undefined" && typeof labGroup === "undefined" && typeof testGroup === "undefined") {
      // repeats until select-controller.js loaded otherwise tom-select functions will not work
      setTimeout(() => {
        this.testMasterToggle();
      }, 100);
    } else {
      this.toggleTomSelectElement(testMaster, !isTestMasterDisabled);
      if(isTestMasterDisabled) {
        testMaster.clear();
      }
      this.visuallyToggleLabGroup(testGroup.getValue() === null);
      this.toggleTomSelectElement(testGroup, isTestMasterDisabled);
      this.toggleTomSelectElement(labGroup, isTestMasterDisabled);
    }
  }

  submitForm(event) {
    const isTestMasterDisabled = this.testMasterToggleInputTarget.checked;
    const testMaster = this.testMasterTarget.tomselect;
    const testGroup = this.testGroupTarget.tomselect;

    // if test master selected and these groups populated by test master then enable groups select boxes when form submitted
    this.visuallyToggleLabGroup(testGroup.getValue() === null);
    this.toggleTomSelectElement(testGroup, true);

    // if test master is disabled and form return value is success clear selected value
    if (event.returnValue && isTestMasterDisabled) {
      this.toggleTomSelectElement(testMaster, true);
      testMaster.clear();
    }
  }

  toggleTomSelectElement(element, isEnabled) {
    isEnabled ? element.enable() : element.disable();
  }

  updateStateOfLabGroup() {
    if (this.testGroupTarget.value === "") {
      this.visuallyToggleLabGroup(false);
    } else {
      this.visuallyToggleLabGroup(true);
    }
  }

  turboStreamRequestForLabGroups(event) {
    const selectBox = event.target;
    const turboParams = JSON.parse(selectBox.dataset.turboParams);
    const params = {
      test_group_id: selectBox.value,
      relate_with_test_master: !this.testMasterToggleInputTarget.checked
    };
    const url = buildUrl(turboParams.url, params);
    const request = buildRequest(url, buildHeaders());
    if(!this.testMasterTarget.value){
      sendTurboStreamRequest(request);
    }
  }

  visuallyToggleLabGroup(shouldDisable) {
    if (this.labGroupTarget.tomselect) {
      const wrapper = this.labGroupTarget.tomselect.wrapper;
      if (shouldDisable) {
        wrapper.classList.add("ts-bg-zinc-100", "pointer-events-none");
      } else {
        wrapper.classList.remove("ts-bg-zinc-100", "pointer-events-none");
      }
    }
  }
}
