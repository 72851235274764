import { Controller } from "@hotwired/stimulus";
import { sendTurboStreamRequest } from "../helpers/turbo_stream_helper"
import { buildHeaders, buildRequest, buildUrl } from "../helpers/build_request"

// Connects to data-controller="warehouse-can-send-to"
export default class extends Controller {
  static targets = [
    "recipientWarehouse",
    "senderWarehouse"
  ];

  connect() {
    if (this.hasRecipientWarehouseTarget == true && this.hasSenderWarehouseTarget) {
      setTimeout(() => {
        this.recipientWarehouseTarget.dispatchEvent(new Event('change'));
      }, 100);
    }
  }
  updateSenderWarehouse() {
    let recipientWarehouseId = this.recipientWarehouseTarget.tomselect.getValue();
    let senderWarehouseTarget = this.senderWarehouseTarget.tomselect;
    let targetUrl = this.recipientWarehouseTarget.dataset.turboTargetUrl;
    
    if(recipientWarehouseId !== '') {
      senderWarehouseTarget.enable();
      const url = buildUrl(targetUrl.replace(':warehouse_id', recipientWarehouseId), {});
      const request = buildRequest(url, buildHeaders());
      sendTurboStreamRequest(request);
    }
    else {
      senderWarehouseTarget.disable();
    }
  }
}
