import { TabulatorFull as Tabulator } from 'tabulator-tables';

function withPageInfo(locale, startItem, endItem, totalRows) {
  switch (locale) {
    case "tr":
      return `<b>${totalRows}</b> kayıttan <b>${startItem}-${endItem}</b> arasındaki kayıtlar gösteriliyor`;
    case "en":
      return `Displaying items <b>${startItem}-${endItem}</b> of <b>${totalRows}</b> in total`;
    default:
      return `Displaying items <b>${startItem}-${endItem}</b> of <b>${totalRows}</b> in total`;
  }
}

function withNoItems(locale) {
  switch (locale) {
    case "tr":
      return "Hiç kayıt bulunamadı";
    case "en":
      return "No items found";
    default:
      return "No items found";
  }
}

function withTotalItems(locale, totalRows) {
  switch (locale) {
    case "tr":
      return `Toplam <b>${totalRows}</b> kayıt gösteriliyor`;
    case "en":
      return `Showing all <b>${totalRows}</b> records`;
    default:
      return `Showing all <b>${totalRows}</b> records`;
  }
}

export function extendPagination() {
  Tabulator.extendModule("page", "pageCounters", {
    pageCounter: function(pageSize, currentRow, currentPage, totalRows, totalPages) {
      let locale = this.table.options.locale;
      let startItem = (currentPage - 1) * pageSize + 1;
      let endItem = Math.min(currentPage * pageSize, totalRows);

      if (totalRows === 0) {
        return withNoItems(locale);
      }

      if (totalRows <= pageSize) {
        return withTotalItems(locale, totalRows);
      }

      return withPageInfo(locale, startItem, endItem, totalRows);
    }
  });
}
