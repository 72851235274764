import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "toggleable" ]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    if (this.checkboxTarget.checked) {
      this.toggleableTarget.classList.remove('hidden')
      this.toggleableTarget.classList.add('block')
    } else {
      this.toggleableTarget.classList.remove('block')
      this.toggleableTarget.classList.add('hidden')
    }
  }
}