import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "step",
    "previousButton",
    "nextButton",
    "submitButton",
    "dateIdentifierInput",
    "mandatoryInput",
    "intervalInput",
    "filterBox",
    "headerBox",
    "validationError"
  ]

  connect() {
    this.totalSteps = this.stepTargets.length;
    this.currentStep = 0;
    this.updateStep();
  }

  next() {
    if (this.validateInputs()) {
      this.currentStep = Math.min(this.currentStep + 1, this.totalSteps - 1);
      this.updateStep();
    }
  }

  previous() {
    this.currentStep = Math.max(this.currentStep - 1, 0);
    this.updateStep();
  }

  // Updates step elements according the current step
  updateStep() {
    const steps = Array.from(document.querySelectorAll(".steps li"));
    steps.forEach((step, index) => {
      step.classList.toggle("step-accent", index <= this.currentStep);
    });
    
    this.stepTargets.forEach((step, index) => {
      step.classList.toggle("hidden", index !== this.currentStep);
    });

    this.updateButtons();
  }

  // Updates buttons according the current form step
  updateButtons() {
    this.hideValidationMessage();
    const isFirstStep = this.currentStep === 0;
    const isLastStep = this.currentStep === this.totalSteps - 1;

    this.previousButtonTarget.classList.toggle("hidden", isFirstStep);
    this.submitButtonTarget.classList.toggle("hidden", !isLastStep);
    this.nextButtonTarget.classList.toggle("hidden", isLastStep);
  }

  // Prevents select more than one date identifier field
  dateIdentifier(e) {
    const isChecked = e.target.checked;
    const otherCheckboxes = this.dateIdentifierInputTargets.filter(checkbox => checkbox !== e.target);
    
    otherCheckboxes.forEach(checkbox => {
      if (checkbox.checked) {
        isChecked ? checkbox.checked = false : e.preventDefault();
      }
    });
  }

  isValidForSubmit(event) {
    if(!this.validateInputs()) {
      event.preventDefault();
    }
  }

  validateInputs() {
    /* 
      0 = header matching step
      1 = date idenetifier selection step
      2 = mandatory field selection step
      3 = filter selection step
      4 = interval definitions steps
    */

    /* TODO:: remove comments after ivdiq team fix csv
    if (this.currentStep === 0) {
      return this.checkBoxChildrens(this.headerBoxTargets);
    }
    */
   
    if (this.currentStep === 1) {
      return this.checkSelectionLength(this.dateIdentifierInputTargets, 0, "option");
    }
  
    if (this.currentStep === 2) {
      return this.checkSelectionLength(this.mandatoryInputTargets, 0, "option");
    }

    if (this.currentStep === 3) {
      return this.checkBoxChildrens(this.filterBoxTargets);
    }

    if (this.currentStep === 4) {
      return this.checkSelectionLength(this.intervalInputTargets, 1, "intervalOption");
    }
  
    return true;
  }
  
  checkSelectionLength(targets, num, validation) {
    const selectedCount = targets.filter(item => item.checked).length;

    if (selectedCount <= num) {
      this.appendValidationMessage(this.validationErrorTarget.dataset[`${validation}`]);
      return false;
    }

    return true;
  }

  checkBoxChildrens(targets) {
    const boxes = targets;
    const invalidBoxes = boxes.filter(box => box.children.length === 0);

    if (invalidBoxes.length > 0) {
      this.appendValidationMessage(this.validationErrorTarget.dataset.box);
      return false;
    }

    return true;
  }

  appendValidationMessage(error) {
    this.validationErrorTarget.classList.remove('hidden');
    this.validationErrorTarget.innerHTML = error;
  }

  hideValidationMessage() {
    this.validationErrorTarget.classList.add('hidden');
  }
}
