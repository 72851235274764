import { Controller } from "@hotwired/stimulus";
import { focusNestedFormTargetElement } from "../helpers/focus_nested_form_target_element";
import { downloadPdf } from "../helpers/download_pdf";

// Connects to data-controller="stock-movement"
export default class extends Controller {

  static targets = [ 
    'stockDetailQuantity',
    'pdfButton',
    'unitTsWrapper'
  ];

  connect() {
    document.addEventListener('focus_stockDetailQuantityTargets',
      (event) => focusNestedFormTargetElement(event.detail, this.stockDetailQuantityTargets));
  }

  // downloand pdf
  pdfButtonTargetConnected() {
    this.pdfButtonTarget.addEventListener('click',(event) => downloadPdf(event));
  }

  unitTsWrapperTargetConnected() {
    setTimeout(() => {
      this.unitTsWrapperTargets.forEach((element) => {
        this.unitTsChangeColor(element);
      });
    }, 0);
  }

  unitTsChangeColor(element) {
    const tsControl = element.querySelector('.ts-wrapper .ts-control');
    if (tsControl) {
      tsControl.style.backgroundColor = "#F4F4F5";
    }
  }
}
