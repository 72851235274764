import {Controller} from "@hotwired/stimulus"
import {buildUrl} from "../helpers/build_request";

export default class extends Controller {
  static targets = ["form", "input", "fullText", "query", 'pageLengthSelect'];

  connect() {
    if (this.hasQueryTarget) {
      this.syncFullTextField()
    }
  }

  submit() {
    if (this.hasPageLengthSelect) {
      this.addPageLengthToParam();
    }
    this.addTabFilterToParams();
    this.notify_success(this.formTarget.dataset.notify);
  }

  addPageLengthToParam() {
    addEventListener("turbo:submit-start", (event) => {
      event.detail.formSubmission.stop();
      let params =  new URLSearchParams(new FormData(event.detail.formSubmission.formElement));
      let url = buildUrl(event.target.action, {});
      params.set('page_length', this.pageLengthSelectTarget.value);
      params.set('keep_open', 'true');
      url.search = params;
      this.turboVisitReplace(url, this.pageLengthSelectTarget.dataset.frameId);
    });
  }

  notify_success(text) {
    document.addEventListener("turbo:frame-render", () => {
      $notification({ text: text, variant: 'success' });
    }, { once: true });
  }

  syncFullTextField(event) {
    this.fullTextTarget.value = this.queryTarget.value;
  }

  checkToSubmit(event) {
    // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    if (event.key === "Enter") {
      event.preventDefault();
      this.addTabFilterToParams();
      this.submitWithTurbo();
    }
  }

  filterFormParams() {
    const filterForm = this.formTarget;
    return new URLSearchParams(new FormData(filterForm));
  }

  turboVisitReplace(url, frameId) {
    Turbo.visit(url, {
      action: "replace",
      frame: frameId
      }
    )
  }

  submitWithTurbo() {
    let filterFormParams = this.filterFormParams();
    const url = buildUrl(this.queryTarget.dataset.turboUrl, {});
    // Too keep hidden filter close, send a param named {keep_open: false};
    // The goal is to update search results using Turbo when the user presses Enter,
    // refresh the filters, and keep the filter hidden.
    filterFormParams.append('keep_open', 'false');
    url.search = filterFormParams;
    this.turboVisitReplace(url, this.queryTarget.dataset.frameId);
    this.notify_success(this.formTarget.dataset.notify);
  }

  submitPageLenght() {
    let pageLengthSelect = this.pageLengthSelectTarget;
    let dataSet = pageLengthSelect.dataset;
    if (dataSet.frameId === undefined) {
      let url = new URL(dataSet.turboUrl);
      url.searchParams.set('page_length', pageLengthSelect.value);
      window.location.href = url.toString();
    } else {
      this.addTabFilterToParams();
      let filterFormParams = this.filterFormParams();
      const url = buildUrl(dataSet.turboUrl, {});
      filterFormParams.append('page_length', pageLengthSelect.value);
      filterFormParams.append('keep_open', 'true');
      url.search = filterFormParams;
      this.turboVisitReplace(url, dataSet.frameId);
      this.notify_success(dataSet.notify);
    }
  }

  // TODO: : Replace empty view component with turbo
  clearInputs(event) {
    if (this.hasInputTarget) {
      this.inputTargets.forEach(element => {
        switch (element.type) {
          case 'number':
            element.value = '';
            break;
          case 'text':
            element.value = '';
            break;
          case 'select-one':
            element.value = 'all';
            break;
          case 'hidden': /* flatpickr generated field */
            element.value = '';
            element._flatpickr.clear();
            break;
          case 'checkbox':
            element.checked = false;
            break;
          case 'select-multiple':
            for (let i = 0; i < element.options.length; i++) {
              element.options[i].selected = false;
              element.tomselect.clear();
            }
            break;
        }
      });
    }
    this.clearFullTextAndQueryTargets();
  }

  clearFullTextAndQueryTargets() {
    if (this.hasFullTextTarget && this.hasQueryTarget && this.queryTarget.value !== '') {
      this.fullTextTarget.value = "";
      this.queryTarget.value = "";
    }
  }

  addTabFilterToParams() {
    const tabFilterName = this.element.dataset.tabFilterName
    if (tabFilterName !== '') {
      const urlParams = new URLSearchParams(window.location.search);
      const tabFilterParam = urlParams.get(tabFilterName);
      const pageLengthParams = urlParams.get("page_length");
      const idParams = urlParams.get("id");
      const labIdParams = urlParams.get("lab_id");


      if (tabFilterParam) {
        this.addHiddenInputToForm(tabFilterParam, tabFilterName);
      }

      if (pageLengthParams) {
        this.addHiddenInputToForm(pageLengthParams, "page_length");
      }

      if (idParams) {
        this.addHiddenInputToForm(idParams, "id");
      }
      if (labIdParams) {
        this.addHiddenInputToForm(labIdParams, "lab_id");
      }
    }
  }

  addHiddenInputToForm(filterParam, name) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = filterParam;
    this.formTarget.appendChild(input);
  }
}
