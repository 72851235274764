import { Controller } from "@hotwired/stimulus"
import { printPdf } from "../helpers/download_pdf";

// Connects to data-controller="warehouse-transfer"
export default class extends Controller {
  static targets = [ 
    "transferQuantity",
    "acceptedQuantity",
    "remainStockInput",
    'pdfButton'
  ]

  connect(){
    this.element.querySelectorAll('[data-check-acceptance]').forEach((el) => {
      el.dispatchEvent(new Event('input'));
    })
  }

  validateAcceptanceQuantity() {
    const remainingStock =  this.calculateRemainingStock();

    // If there is remaining stock, the inputs will be active, if not, the inputs will be disabled.
    this.changeStockInputDisabled(remainingStock <= 0);
  }

  calculateRemainingStock() {
    return this.transferQuantityTarget.value - this.acceptedQuantityTarget.value;
  }
  
  changeStockInputDisabled(isDisabled) {
    this.remainStockInputTargets.forEach((input) => {
      input.disabled = isDisabled;
    });
  }

  // print pdf
  pdfButtonTargetConnected() {
    this.pdfButtonTarget.addEventListener('click',(event) => printPdf(event));
  }
}