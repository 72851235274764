import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "element"];

  // Connects to data-controller="nested-dom-id"
  connect() {
    this.updateElements();
  }

  // Updates the IDs of elements based on input values
  updateElements() {
    const inputValues = this.fetchValues();
  
    this.elementTargets.forEach(element => {
      const initialId = element.getAttribute('data-initial-id') || element.id;
      const newId = this.generateDomId(initialId, ...inputValues);
      element.id = newId;
    });
  }

  fetchValues() {
    return this.inputTargets.flatMap(input => {
      const value = input.value.trim();
      if (value === "") return []; // Skip empty values
  
      return value.includes(' - ') 
        ? value.split(' - ') 
        : [value];
    });
  }

  // Generates a new DOM ID based on initial ID and input values
  generateDomId(initialId, ...args) {
    let processedArgs = args.map(arg => {
      // Clean each argument by removing special characters and trimming spaces
      return arg.toString().trim().replace(/[^a-zA-Z0-9\-_]/g, '-');
    }).filter(Boolean);

    processedArgs = processedArgs.filter(arg => arg !== '-');

    // If we have no arguments, return the initial ID as is
    if (processedArgs.length === 0) {
      return initialId;
    }
  
    // Combine initial ID with processed arguments to create the new ID
    const suffix = processedArgs.join('_');
    return `${initialId}_${suffix}`;
  }

  update(e) {
    this.updateElements();
  }
}
