import {Controller} from "@hotwired/stimulus";
import {buildHeaders, buildRequest, buildUrl} from "../helpers/build_request";
import {sendTurboStreamRequest} from "../helpers/turbo_stream_helper";

// Connects to data-controller="usage"
export default class extends Controller {
  static targets = ["stockCard", "deviceCard", "lotWithExpire"];

  stockTurboStreamRequest(event) {
    // fetch selected stock card and replace row with it
    const selectBox = event.target;
    const turboParams = JSON.parse(selectBox.dataset.turboParams);
    const params = { stock_card_id: selectBox.value, ...turboParams };
    delete params.url;
    if (params.stock_card_id !== '' && params.stock_card_id !== selectBox.dataset.lastSelectedStockCardId) {
      const url = buildUrl(turboParams.url, params);
      const request = buildRequest(url, buildHeaders());
      sendTurboStreamRequest(request);
    }
  }

  preventRecurringLotWithExpire(event) {
    // Same lot and expire date can't be select
    const selectBox = event.target;
    const valueExists = this.lotWithExpireTargets
                            .filter(c => c != event.target)
                            .some(el => el.value === selectBox.value);
    if (valueExists) {
      event.preventDefault();
      selectBox.tomselect.setValue([]);
      $notification({ text: selectBox.dataset.lotWithExpireError, variant: 'error' });
    }
  }

  updateDeviceCardState(e) {
    // If warehouse selected enable device card else disable
    if (e.target.value != '') {
      this.deviceCardTarget.tomselect.enable();
    }
    else {
      this.deviceCardTarget.tomselect.disable();
    }
  }
}
