import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "spinner"];
  static values = {
    frameId: String
  }

  connect() {
    document.addEventListener("turbo:frame-load", this.hideSpinner.bind(this));
  }

  disconnect() {
    document.removeEventListener("turbo:frame-load", this.hideSpinner.bind(this));
  }

  showSpinner() {
    this.contentTarget.classList.add("hidden");
    this.spinnerTarget.classList.remove("hidden");
  }

  hideSpinner(event) {
    if (event.target.id === this.frameIdValue) {
      this.contentTarget.classList.remove("hidden");
      this.spinnerTarget.classList.add("hidden");
    }
  }
}
