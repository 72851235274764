import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleText"];

  toggleText() {
    let currentText = this.toggleTextTarget.textContent.trim();
    let showText = this.toggleTextTarget.dataset.toggleTextOriginal;
    let hideText = this.toggleTextTarget.dataset.toggleTextAlternate;

    if (currentText === showText) {
      this.toggleTextTarget.textContent = hideText;
    } else {
      this.toggleTextTarget.textContent = showText;
    }
  }

  toggleSvgIcon() {
    const icon = this.toggleTextTarget;
    const isRotated = icon.classList.contains("rotate180");

    if (isRotated) {
      icon.classList.remove("rotate180");
      icon.innerHTML = this.toggleTextTarget.dataset.toggleTextOriginal.trim();
    } else {
      icon.classList.add("rotate180");
      icon.innerHTML = this.toggleTextTarget.dataset.toggleTextAlternate.trim();
    }
  }
}
