Turbo.StreamActions.update_select_options = function () {
  this.targetElements.forEach((target) => {
    target.tomselect.clear();
    target.tomselect.clearOptions();
    target.replaceChildren(...this.templateContent.children);
    target.tomselect.refreshOptions();
    target.tomselect.sync();

    const selectedValue = Array.from(this.templateContent.children)
      .find(option => option.selected)?.value;
    if (selectedValue) {
      target.tomselect.setValue(selectedValue);
    } else {
      target.tomselect.setValue("", true);
      target.value = null;
    }
  });
};