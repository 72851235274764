import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="document-export"

export default class extends Controller {
  static targets = ['form', 'exportButton']

  connect() {
    super.connect()
  }

  formTargetConnected() {
    const submitters = this.exportButtonTargets;
    const filterForm = this.formTarget;
    submitters.forEach(submitter => {
      submitter.addEventListener('click', (event) => {
        const submitWithParams = JSON.parse(submitter.dataset.submitWithParams);
        let filterFormParams = new URLSearchParams(new FormData(filterForm));
        Object.entries(submitWithParams).forEach(([key, value]) => {
          filterFormParams.append(key, value);
        });

        window.location.href = `${filterForm.action}?${filterFormParams.toString()}`;
      })
    });
  }
};