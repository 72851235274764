import { Controller } from "@hotwired/stimulus"
import Tabulator from "../../extensions/TabulatorExtended"
import { searchSvg } from "../../helpers/constants";

// Connects to data-controller="tabulator--stock-card-assign"
export default class extends Controller {
  static targets = ["hiddenInput", "table"]
  static values = {
    url: String,
    columns: Array,
    placeholder: String,
    initialSelectedIds: Array
  }

  connect() {
    this.selectedIds = new Set(this.initialSelectedIdsValue || []);
    this.groupCheckboxes = new Map();
    this.initializeTabulator();
    this.updateSelectedStockCardCount();
  }

  disconnect() {
    this.tabulator?.destroy();
  }

  initializeTabulator() {
    this.tabulator = new Tabulator(this.tableTarget, {
      ajaxURL: this.urlValue,
      ajaxConfig: "GET",
      layout: "fitColumns",
      columns: this.constructColumns(),
      groupBy: "test_group",
      groupStartOpen: false,
      selectable: false,
      groupHeader: this.groupHeaderFormatter.bind(this),
    })

    this.tabulator.on("tableBuilt", () => {
      this.syncSelections();
      this.updateHeaderCheckbox();
      this.syncGroupHeaders();
      this.headerFilterInput();
    })
  }

  constructColumns() {
    return [
      {
        titleFormatter: this.headerCheckboxFormatter.bind(this),
        formatter: this.rowCheckboxFormatter.bind(this),
        cellClick: this.rowCheckboxClick.bind(this),
        headerSort: false,
        hozAlign: "center",
        width: 150,
        field: "select",
      },
      ...this.columnsValue,
    ]
  }

  createCheckbox() {
    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    return checkbox;
  }

  headerCheckboxFormatter() {
    const checkbox = this.createCheckbox();
    checkbox.addEventListener("change", () => {
      const checked = checkbox.checked;
      this.tabulator.getRows().forEach((row) => {
        const id = row.getData().id;
        checked ? this.selectedIds.add(id) : this.selectedIds.delete(id);
        this.updateRowCheckbox(row.getCell("select"), id);
      })
      this.updateHiddenInputs();
      this.syncGroupHeaders();
    })
    this.headerCheckbox = checkbox;
    return checkbox
  }

  rowCheckboxFormatter(cell) {
    const checkbox = this.createCheckbox();
    const id = cell.getRow().getData().id;
    checkbox.checked = this.selectedIds.has(id);
    return checkbox;
  }

  rowCheckboxClick(e, cell) {
    e.stopPropagation();
    const id = cell.getRow().getData().id;
    this.selectedIds.has(id) ? this.selectedIds.delete(id) : this.selectedIds.add(id);
    this.updateRowCheckbox(cell, id);
    this.updateHiddenInputs();
    this.updateHeaderCheckbox();
    this.syncGroupHeaders();
  }

  updateRowCheckbox(cell, id) {
    const checkbox = cell.getElement().querySelector('input[type="checkbox"]');
    if (checkbox) checkbox.checked = this.selectedIds.has(id)
  }

  updateHiddenInputs() {
    this.hiddenInputTarget.innerHTML = "";
    if (this.selectedIds.size > 0) {
      this.selectedIds.forEach((id) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = "warehouse[stock_card_ids][]";
        input.value = id;
        this.hiddenInputTarget.appendChild(input);
      })
    } else {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = "warehouse[stock_card_ids][]";
      input.value = "";
      this.hiddenInputTarget.appendChild(input);
    }
  }

  syncSelections() {
    this.tabulator.getRows().forEach((row) => {
      const id = row.getData().id;
      this.updateRowCheckbox(row.getCell("select"), id);
    })
  }

  updateHeaderCheckbox() {
    const totalRows = this.tabulator.getRows().length;
    const selectedRows = this.selectedIds.size;
    if (this.headerCheckbox) {
      this.headerCheckbox.checked = selectedRows === totalRows && totalRows > 0;
      this.headerCheckbox.indeterminate = selectedRows > 0 && selectedRows < totalRows;
    }
  }

  groupHeaderFormatter(value, count, data, group) {
    const container = document.createElement("span");
    const checkbox = this.createCheckbox();
    const groupRows = group.getRows();
    const groupData = groupRows.map((row) => row.getData());
    const allSelected = groupData.every((item) => this.selectedIds.has(item.id));
    const someSelected = groupData.some((item) => this.selectedIds.has(item.id));

    checkbox.checked = allSelected;
    checkbox.indeterminate = !allSelected && someSelected;

    checkbox.addEventListener("change", (e) => {
      e.stopPropagation();
      const checked = checkbox.checked;
      groupRows.forEach((row) => {
        const id = row.getData().id;
        checked ? this.selectedIds.add(id) : this.selectedIds.delete(id);
        this.updateRowCheckbox(row.getCell("select"), id);
      })
      this.updateHiddenInputs();
      this.updateHeaderCheckbox();
    })

    container.appendChild(checkbox);
    this.groupCheckboxes.set(group.getKey(), checkbox);

    const label = document.createElement("span");
    label.textContent = `${value} (${count})`;
    label.style.cursor = "pointer";
    label.addEventListener("click", (e) => {
      e.stopPropagation();
      group.toggle();
    })

    container.appendChild(label);
    return container;
  }

  syncGroupHeaders() {
    this.tabulator.getGroups().forEach((group) => {
      const groupRows = group.getRows();
      const groupData = groupRows.map((row) => row.getData());
      const checkbox = this.groupCheckboxes.get(group.getKey());
      if (checkbox) {
        const allSelected = groupData.every((item) => this.selectedIds.has(item.id));
        const someSelected = groupData.some((item) => this.selectedIds.has(item.id));
        checkbox.checked = allSelected;
        checkbox.indeterminate = !allSelected && someSelected;
      }
    })
  }

  toggleAll() {
    for (let i = 0; i < this.tabulator.getGroups().length; i++) {
      this.tabulator.getGroups()[i].toggle();
    }
  }

  sortSelectedRows() {
    const rows = this.tabulator.getRows();
    const selectedRows = rows.filter(row => this.selectedIds.has(row.getData().id));
    const unselectedRows = rows.filter(row => !this.selectedIds.has(row.getData().id));
    const sortedRows = [...selectedRows, ...unselectedRows];
    this.tabulator.setData(sortedRows.map(row => row.getData()));
    this.toggleAll();
  }

  headerFilterInput() {
    const placeholderText = this.placeholderValue; 
    const headerFilters = this.tableTarget.querySelectorAll('.tabulator-header .tabulator-header-filter input');
  
    headerFilters.forEach((input) => {
      input.placeholder = placeholderText;
      const container = document.createElement('div');
      const styledInput = input.cloneNode();
      styledInput.classList.add('pr-2');
  
      const icon = document.createElement('div');
      icon.classList.add('tabulator-header-search-icon');
      icon.innerHTML = searchSvg;

      container.append(styledInput, icon);
      input.replaceWith(container);
    });
  }  

  updateSelectedStockCardCount() {
    const count = this.selectedIds.size;
    const countElement = document.getElementById("selectedStockCardCount");
    countElement.textContent = count;
  }
}
