import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {

  static values = {
    type: String,
    dataset: Array
  };

  initialize() {
    this.chart = null;
  }

  connect() {
    this[this.typeValue]();
  }
  
  generateEfficiencyByLabChart() {
    const data = this.datasetValue;
    const labels = data.map(item => item.lab_name);
    const efficiencyRate = data.map(item => item.efficiency_rate);
  
    const options = {
      series: [{
        data: efficiencyRate,
        name: this.element.dataset.efficiencyRate
      }],
      chart: {
        height: 350,
        type: 'bar'
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          horizontal: true,
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      annotations: {
        xaxis: [
          {
            x: this.element.dataset.efficiencyTarget,
            strokeDashArray: 4,
            borderColor: '#ff7800',
            opacity: 1
          }
        ]
      },
      xaxis: {
        categories: labels,
        labels: {
          formatter: function(val) {
            return val.toFixed(1) + " %";
          }
        },
      },
      tooltip: {
        shared: false,
        x: {
          formatter: function(val) {
            return val;
          }
        },
        y: {
          formatter: function(val) {
            return val.toFixed(1) + " %";
          }
        }
      },
    };
  
    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  generateEfficiencyByPeriodicChange() {
    const data = this.datasetValue;
    const months = data.map(item => item.month);
    const efficiencyRate = data.map(item => item.efficiency_rate);

    var options = {
      series: [{
        name:  this.element.dataset.efficiencyRate,
        data: efficiencyRate
      }],
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      yaxis: {
        labels: {
          formatter: function(val) {
            return val.toFixed(1) + " %";
          }
        },
      },
      xaxis: {
        categories: months,
      },
      annotations: {
        yaxis: [
          {
            y: this.element.dataset.efficiencyTarget,
            strokeDashArray: 4,
            borderColor: '#ff7800',
            opacity: 1
          }
        ]
      },
    };

    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }
  
  disconnect() {
    if (this.chart) {
      this.chart.destroy(); 
      this.chart = null;
    }
  }
}
