import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "warehouseDemandTransferModal", "documentationUploadModal"];

  connect() {
    super.connect();
    this.boundHandleKeyDown = this.handleKeyDown.bind(this);
    document.addEventListener("keydown", this.boundHandleKeyDown);
    
    this.boundCloseModal = this.closeModal.bind(this);
    document.addEventListener('closeModal', this.boundCloseModal);

    this.initializeGeneralModal();
    this.initializeDocumentationUploadModal();
  }

  disconnect() {
    super.disconnect();
    document.removeEventListener("keydown", this.boundHandleKeyDown);
    document.removeEventListener('closeModal', this.boundCloseModal);
  }

  initializeGeneralModal() {
    if(this.hasModalTarget) {
      const uniqueId = this.generateUniqueId();
      this.modalTarget.id = uniqueId;
      this.activateModal(uniqueId);
    }
  }

  initializeDocumentationUploadModal() {
    if (this.hasDocumentationUploadModalTarget) {
      const modal = new Modal('#documentationUploadModal');
      modal.isActive = true;
    }
  }

  generateUniqueId() {
    return 'modal-' + Math.random().toString(36).substring(2);
  }

  activateModal(elementId) {
    const modal = new Modal(`#${elementId}`);
    modal.isActive = true;
  }

  handleKeyDown(event) {
    if (event.key === "Escape") {
      if (this.hasModalTarget) {
        this.closeModal();
      }
      if (this.hasWarehouseDemandTransferModalTarget) {
        this.closeWarehouseDemandTransferModal();
      }
      if (this.hasDocumentationUploadModalTarget) {
        this.closeDocumentationUploadModal();
      }
    }
  }

  openModal() {
    this.modalTarget.classList.add("show");
  }

  closeModal() {
    this.modalTarget.classList.remove("show");
  }

  closeWarehouseDemandTransferModal() {
    this.warehouseDemandTransferModalTarget.classList.remove("show");
  }

  openDocumentationUploadModal() {
    this.documentationUploadModalTarget.classList.add("show");
  }

  closeDocumentationUploadModal() {
    this.documentationUploadModalTarget.classList.remove("show");
  }
}