import {Controller} from "@hotwired/stimulus"
import Tabulator from "../../extensions/TabulatorExtended";
import {buildHeaders, buildRequest, buildUrl} from "../../helpers/build_request";
import {sendTurboStreamRequest} from "../../helpers/turbo_stream_helper";

// Connects to data-controller="tabulator--overstock-reports"
export default class extends Controller {
  static values = {
    url: String,
    detailUrl: String,
    columns: Array,
    locale: String
  }

  connect() {
    this.tabulator = new Tabulator(this.element, {
      ajaxURL: this.urlValue,
      ajaxConfig: "GET",
      layout: "fitData",
      pagination: true,
      paginationMode: 'local',
      paginationSize: 50,
      paginationSizeSelector: [15, 25, 50, 100],
      sortMode: 'local',
      columns: this.columnsValue.map(column => {
        if (column.field === "id") {
          return {
            ...column,
            formatter: this.detailIconFormatter.bind(this),
            cellClick: this.sendTurboWhenClick.bind(this),
          };
        }
        return column;
      }),
      locale: this.localeValue,
      dataLoaderLoading: "<div class='tabulator-spinner'></div>",
      paginationCounter: "pageCounter",
    });
  }

  detailIconFormatter(cell, formatterParams, onRendered) {
    const iconPath = "/assets/file_search.svg";
    const icon = document.createElement("img");
    icon.src = iconPath;
    icon.alt = "Details";
    icon.style.width = "20px";
    return icon;
  }

  sendTurboWhenClick(e, cell) {
    const stockCardId = cell.getRow().getData().id;
    const stockDetailUrl = this.detailUrlValue;
    const url = buildUrl(stockDetailUrl, {stock_card_id: stockCardId});
    const headers = buildHeaders();
    const request = buildRequest(url, headers);
    sendTurboStreamRequest(request);
  }


  disconnect() {
    if (this.tabulator) {
      this.tabulator.destroy();
      this.tabulator = null;
    }
  }
}
