import { Controller } from "@hotwired/stimulus";
import Tabulator from "../../extensions/TabulatorExtended";
import { warningExclamationSvg, redUpArrowSvg, redDownArrowSvg } from "../../helpers/constants";

// Connects to data-controller="tabulator--stock-observations"
export default class extends Controller {
  static values = {
    url: String,
    columns: Array,
    locale: String,
  };

  connect() {
    this.tabulator = new Tabulator(this.element, {
      ajaxURL: this.urlValue,
      ajaxConfig: "GET",
      layout: "fitData",
      pagination: true,
      paginationMode: "remote",
      paginationSize: 15,
      paginationSizeSelector: [15, 25, 50, 100],
      sortMode: "remote",
      columns: this.processedColumns(this.columnsValue),
      locale: this.localeValue,
      dataLoaderLoading: "<div class='tabulator-spinner'></div>",
      paginationCounter: "pageCounter",
      ajaxRequesting: (url, params) => {
        this.processedSorters(params);
        return true;
      }
    });
  }

  criticalStockQuantityFormatter(cell) {
    const rowData = cell.getRow().getData();
    const { value: criticalStock, total_quantity_below_critical_stock: message } = cell.getValue();

    const container = document.createElement("div");
    container.classList.add("flex", "justify-end", "items-center", "space-x-2");
    container.setAttribute("title", message);

    const span = document.createElement("span");
    span.textContent = criticalStock;
    container.appendChild(span);

    if (criticalStock > rowData.stock_quantity.value) {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(warningExclamationSvg, "image/svg+xml");
      const svgElement = svgDoc.querySelector("svg");
      if (svgElement) {
        svgElement.classList.add("mr-2", "w-4", "h-4", "text-warning");
        container.appendChild(svgElement);
      }
    } else {
      const emptyIcon = document.createElement("div");
      emptyIcon.classList.add("mr-2", "w-4", "h-4");
      container.appendChild(emptyIcon);
    }

    return container;
  }

  stockCoverageFormatter(cell) {
    const { value: coverage, display, maximum_period: maxPeriod, minimum_period: minPeriod } = cell.getValue();

    const container = document.createElement("div");
    container.classList.add("flex", "justify-end", "items-center", "space-x-2");

    const span = document.createElement("span");
    span.textContent = display;
    container.appendChild(span);

    const iconContainer = document.createElement("div");
    iconContainer.classList.add("mr-2", "w-4", "h-4");

    const icon = document.createElement("div");

    if (coverage > maxPeriod) {
      icon.innerHTML = redUpArrowSvg;
    } else if (coverage < minPeriod) {
      icon.innerHTML = redDownArrowSvg;
    } else {
      icon.classList.add("badge", "text-success");
      const statusDot = document.createElement("div");
      statusDot.classList.add("w-1.5", "h-1.5", "rounded-full", "bg-current");
      icon.appendChild(statusDot);
    }

    iconContainer.appendChild(icon);
    container.appendChild(iconContainer);

    return container;
  }

  processedColumns(columns) {
    const formatterMap = {
      critical_stock_quantity: this.criticalStockQuantityFormatter.bind(this),
      stock_coverage_monthly: this.stockCoverageFormatter.bind(this),
    };

    return columns.map((column) => {
      if (formatterMap[column.field]) {
        return {
          ...column,
          formatter: formatterMap[column.field],
        };
      }
      return column;
    });
  }

  processedSorters(params) {
    if (params.sort.length > 0) {
      const fieldMapping = {
        company: "companies.name",
        test_master: "test_masters.name",
        test_group: "test_groups.name",
        stock_main_group: "stock_main_groups.name",
        stock_sub_group: "stock_sub_groups.name",
        brand: "brands.name",
        purchase_unit: "string_translations.value",
        suppliers: "suppliers.name",
        storage_condition: "storage_conditions.name",
      };

      const sorter = params.sort[0];
      if (fieldMapping[sorter.field]) {
        sorter.field = fieldMapping[sorter.field];
      }
    }
  }

  disconnect() {
    if (this.tabulator) {
      this.tabulator.destroy();
      this.tabulator = null;
    }
  }
}
