import Flatpickr from "stimulus-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr";

export default class extends Flatpickr {
  static values = {
    maxDayCount: Number,
    allowInput: Boolean,
    dateFormat: { type: String },
  };

  connect() {
    this.config = {
      locale: Turkish,
      enableTime: false,
      time_24hr: true,
      allowInput: this.allowInputValue,
      onChange: this.handleDateChange.bind(this),
      ...(this.dateFormatValue && { dateFormat: this.dateFormatValue })
    };

    if (this.allowInputValue) {
      this.config.parseDate = (datestr) => {
        return this.customParseDate(datestr);
      };
      this.config.formatDate = (date) => {
        return this.formatDate(date);
      };
    }

    super.connect();
  }

  handleDateChange(selectedDates, dateStr, instance) {
    if (this.hasMaxDayCountValue && this.maxDayCountValue !== 0) {
      this.dateRangeMaxDayCount(selectedDates);
    }
  }

  dateRangeMaxDayCount(selectedDates) {
    if (selectedDates.length === 2) {
      const startDate = selectedDates[0];
      const endDate = selectedDates[1];
      const diffInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

      if (diffInDays + 1 > this.maxDayCountValue) {
        alert(`${this.maxDayCountValue} günden fazla seçim yapamazsınız. Lütfen tekrar deneyin.`);
        this.fp.clear();
      }
    }
  }

  customParseDate(datestr) {
    const formats = ["DD.MM.YYYY", "DD/MM/YYYY", "YYYY-MM-DD"];
    let parsedDate = null;

    for (let format of formats) {
      parsedDate = this.parseDate(datestr, format);
      if (parsedDate) break;
    }

    return parsedDate;
  }

  parseDate(dateString, format) {
    const parts = dateString.split(/[./-]/);
    if (parts.length !== 3) return null;

    let day, month, year;
    switch (format) {
      case "DD.MM.YYYY":
      case "DD/MM/YYYY":
        [day, month, year] = parts;
        break;
      case "YYYY-MM-DD":
        [year, month, day] = parts;
        break;
      default:
        return null;
    }

    day = parseInt(day, 10);
    month = parseInt(month, 10);
    year = parseInt(year, 10);

    if (year < 100) year += 2000;

    if (month < 1 || month > 12 || day < 1 || day > 31 || year < 2000 || year > 2099) {
      return null;
    }

    return new Date(year, month - 1, day);
  }

  formatDate(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }
}
