import { TabulatorFull as Tabulator } from 'tabulator-tables';

export function extendLocalizations() {
  Tabulator.extendModule("localize", "langs", {
    "tr": {
      "pagination": {
        "prev": "Önceki",
        "prev_title": "Önceki Sayfa",
        "next": "Sonraki",
        "next_title": "Sonraki Sayfa",
        "page_size": "kayıt göster"
      }
    },
    "en": {
      "pagination": {
        "prev": "Previous",
        "prev_title": "Previous Page",
        "next": "Next",
        "next_title": "Next Page",
        "page_size": "show records"
      }
    }
  });
}
