import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "editButton", "cancelButton"]
    
  edit() {
    this.toggleButtonVisibility();
    this.enableInputs();
  }

  cancel() {
    this.toggleButtonVisibility();
    this.disableInputs();
  }

  toggleButtonVisibility() {
    this.editButtonTarget.classList.toggle("hidden");
    this.cancelButtonTarget.classList.toggle("hidden");
  }

  enableInputs() {
    this.inputTargets.forEach((input) => {
      input.disabled = false;
    });
  }

  disableInputs() {
   this.inputTargets.forEach((input) => {
      input.disabled = true;
    });
  }
}