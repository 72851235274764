import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["gridItem"];

  connect() {
    super.connect();
  }

  toggleGrid() {
    this.gridItemTarget.classList.toggle("hidden");
    this.gridItemTarget.classList.toggle("grid");
  }
}
