import { Toggle } from 'tailwindcss-stimulus-components'

export default class extends Toggle {
  connect() {
      super.connect();
      // to enable the initial display
    if (this.element.dataset.toggleDefaultOpen === "true") {
      this.openValue = true;
    }
  }

  hideToggleable() {
    this.openValue = false;
  }
}
