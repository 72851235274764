let fetchedData;

import { Controller } from "@hotwired/stimulus";
import { sendTurboStreamRequest } from "../helpers/turbo_stream_helper"
import { buildHeaders, buildRequest, buildUrl } from "../helpers/build_request"
// import TomSelect from "tom-select";

// Connects to data-controller="stock-detail"
export default class extends Controller {
  static targets = [
    "stockCard",
    "unit",
    "unitField",
    "lotTracking",
    "expireDateInput",
    "lotInput",
    "barcodeNo",
    "stockObservation", // TODO:: unnecessary target or wrong name ?
    "consumptionType",
    "demandQuantity",
    "quantity",
    "unitMovement",
    "stockEntry",
    "setBarcode",
    "lotAndExpireDateInput",
  ];

  connect() {
    if (this.hasStockEntryTarget || this.hasStockObservationTarget) {
      this.handleRenderStockEntryAndObservation();
    }
  }

  handleRenderStockEntryAndObservation() {
    if (typeof this.stockCardTarget.tomselect === "undefined") {
      setTimeout(() => {
        this.handleRenderStockEntryAndObservation();
      }, 100);
    } else {
      this.updateFields();
    }
  }

  updateFields() {
    let selectedCardId = this.stockCardTarget.tomselect.getValue();
    const isSetBarcodeNo = this.hasSetBarcodeTarget;
    if (selectedCardId == "") {
      let unitInput = this.unitTarget.tomselect;
      unitInput.clear();
      unitInput.clearOptions();
      if (this.hasStockObservationTarget) {
        this.barcodeNoTarget.value = "-";
      }
    } else {
      if (this.hasStockObservationTarget == true) {
        let warehouseId = document.getElementById("warehouse-input").value;

        fetch(
          "/warehouse_stock_detail?stock_card_id=" +
            selectedCardId +
            "&warehouse_id=" +
            warehouseId,
        )
          .then((response) => response.json())
          .then((data) => this.updateInput(data));
      } else {
        fetch(
          "/stock_card_detail?id=" +
            selectedCardId +
            "&is_stock_entry=" +
            this.hasStockEntryTarget,
        )
          .then((response) => response.json())
          .then((data) =>
            this.updateInput(
              data,
              this.hasStockEntryTarget || isSetBarcodeNo,
            ),
          );
      }
      if (this.hasStockObservationTarget || this.hasStockEntryTarget) {
        const quantity = this.quantityTarget;
        quantity.focus();
      }

      const event = new CustomEvent('unitOptionsUpdated', { bubbles: true });
      this.element.dispatchEvent(event);
    }
  }

  updateInput(data, isSetBarcode) {
    fetchedData = data;

    if (this.hasLotInputTarget || this.hasLotAndExpireDateInputTarget) {
      
      this.lotMiatState(data["lot_tracking"]);

      if (this.hasStockEntryTarget == false) {
        let lotAndExpireInput = this.lotAndExpireDateInputTarget.tomselect;
        this.refreshSelectOptions(lotAndExpireInput, data["lots_and_expire_dates"], "lot_and_expire");
      }
    }

    let unitInput = this.unitTarget.tomselect;
    this.refreshSelectOptions(unitInput, data["units"], "unit");

    const setUnitWithPurchaseUnitAuto = this.stockCardTarget.dataset.stockDetailSetUnitWithPurchaseUnitAuto === "true";
    if (setUnitWithPurchaseUnitAuto) {
      const purchaseUnit = data["units"].find(unit => unit.purchase_unit === true);
      if (purchaseUnit) {
        unitInput.setValue(purchaseUnit.value);
      }
    }

    if (this.hasStockEntryTarget == true) {
      unitInput.wrapper.classList.add("disabled");
    }

    if (isSetBarcode) {
      const value = data["barcode_no"] && data["barcode_no"].length > 0 ? data["barcode_no"] : '-';
      this.barcodeNoTarget.value = value;
    }

    if (this.hasLotTrackingTarget == true) {
      this.lotTrackingTarget.checked = data["lot_tracking"];
      this.barcodeNoTarget.value = data["barcode_no"];
    }

    if (this.hasConsumptionTypeTarget == true)
      this.consumptionTypeTarget.innerHTML = data["consumption_tracking"];

    if (this.hasStockObservationTarget) {
      this.updateSelectedLotAndExpireInput();
      let lotAndExpireInput = this.lotAndExpireDateInputTarget.tomselect;
      this.refreshSelectOptions(lotAndExpireInput, data["lots_and_expire_dates"], "lot_and_expire");
    }

    if (this.hasDemandQuantityTarget) {
      this.demandQuantityTarget.focus();
    }
  }

  updateLotAndExpire() {
    let lotAndExpireDate = this.lotAndExpireDateInputTarget.value.split(",");
    let lot = lotAndExpireDate[0];
    let expireDate = lotAndExpireDate[1];

    if (lot != "" && expireDate != "") {
      this.lotInputTarget.value = lot;
      this.expireDateInputTarget.value = expireDate;
    }
  }

  updateSelectedLotAndExpireInput() {
    let lot = this.lotInputTarget.value;
    let expireDate = this.expireDateInputTarget.value;

    if (lot != "" && expireDate != "") {
      this.lotAndExpireDateInputTarget.tomselect.setValue(
        lot + "," + expireDate,
      );
    }
  }

  refreshSelectOptions(input, data, type) {
    input.clear();
    input.clearOptions();
    input.addOptions(data);
    input.refreshItems();

    if (type == "unit") {
      if (this.hasStockEntryTarget) {
        const purchaseUnit = data.filter(item => item.purchase_unit == true);

        if (purchaseUnit[0] != undefined) {
          input.setValue(purchaseUnit[0].value);
        }
      }
    }
  }

  lotMiatState(lot_tracking) {
    if (this.hasStockObservationTarget) {
      if (lot_tracking == false) {
        this.lotAndExpireDateInputTarget.disabled = true;
        this.lotAndExpireDateInputTarget.parentElement.children[1].classList.add(
          "bg-slate-100",
          "pointer-events-none",
        );
        this.lotInputTarget.disabled = true;
        this.expireDateInputTarget.disabled = true;
      } else {
        this.lotAndExpireDateInputTarget.disabled = false;
        this.lotAndExpireDateInputTarget.parentElement.children[1].classList.remove(
          "bg-slate-100",
          "pointer-events-none",
        );
        this.lotInputTarget.disabled = false;
        this.expireDateInputTarget.disabled = false;
      }
    }

    if (this.hasStockEntryTarget) {
      if (lot_tracking == false) {
        this.lotInputTarget.disabled = true;
        this.lotInputTarget.classList.remove("bg-transparent");
        this.lotInputTarget.classList.add(
          "bg-slate-100",
          "pointer-events-none",
        );
        this.expireDateInputTarget.disabled = true;
        this.expireDateInputTarget.classList.remove("bg-transparent");
        this.expireDateInputTarget.classList.add(
          "bg-slate-100",
          "pointer-events-none",
        );
      } else {
        this.lotInputTarget.disabled = false;
        this.lotInputTarget.classList.add("bg-transparent");
        this.lotInputTarget.classList.remove(
          "bg-slate-100",
          "pointer-events-none",
        );
        this.expireDateInputTarget.disabled = false;
        this.expireDateInputTarget.classList.add("bg-transparent");
        this.expireDateInputTarget.classList.remove(
          "bg-slate-100",
          "pointer-events-none",
        );
      }
    }
  }

  displayTotalStock(event) {
    const stockCardId = this.stockCardTarget.tomselect.getValue();
    const lotAndExpireDate = this.lotAndExpireDateInputTarget.value.split(",");
    const warehouseId = document.getElementById("warehouse-input").value;
  
    const lot = lotAndExpireDate[0];
    const expireDate = lotAndExpireDate[1];
  
    const turboParams = JSON.parse(event.target.dataset.turboParamsForDisplayTotalStock);
  
    const params = { id: stockCardId, warehouse_id: warehouseId, ...turboParams };

    if (lot !== '') {
      params.lot = lot;
    }
  
    if (expireDate !== '') {
      params.expire_date = expireDate;
    }
  
    delete params.url;
    if (params.id !== '') {
      const url = buildUrl(turboParams.url, params);
      const request = buildRequest(url, buildHeaders());
      sendTurboStreamRequest(request);
    }
  }
}
