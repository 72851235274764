import {Controller} from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {
  static values = {
    card: String,
    chartType: String,
    consumptionPointIds: Array
  };

  connect() {
    super.connect();
    this.getStockDashboardChartOptions();
  }

  getStockDashboardChartOptions() {
    fetch(`/stock_card_chart_options?id=${this.cardValue}`)
      .then((response) => response.json())
      .then((data) => {
        this.initializeStockDashboardChart(data);
      });
  }

  initializeStockDashboardChart(data) {
    const params = {
      series: data["options"],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "category",
        categories: data["months"],
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    };

    const chart = document.querySelector("#stockCardChart");

    setTimeout(() => {
      chart._chart = new ApexCharts(chart, params);
      chart._chart.render();
    });
  }
}
