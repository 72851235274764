import NestedForm from 'stimulus-rails-nested-form'
export default class extends NestedForm {
    
  connect() {
    super.connect();
  }

  add(t) {
    t.preventDefault();
    const targetIdentifier = new Date().getTime().toString()
    const e = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, targetIdentifier);
    this.targetTarget.insertAdjacentHTML("afterend", e);
  }
}