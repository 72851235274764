import { Controller } from "@hotwired/stimulus"
import { downloadPdf } from "../helpers/download_pdf";

// Connects to data-controller="stock-transactions"
export default class extends Controller {
  static targets = [
    'pdfButton'
  ];

  // download pdf
  pdfButtonTargetConnected() {
    this.pdfButtonTarget.addEventListener('click',(event) => downloadPdf(event));
  }
}
