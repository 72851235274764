// app/javascript/controllers/tabulator/lab_clinics_controller.js
import { Controller } from "@hotwired/stimulus";
import Tabulator from "../../extensions/TabulatorExtended";
import { handleDeleteClick } from "../../helpers/tabulator_helpers";
import { deleteSvg, editSvg } from "../../helpers/constants";

// Connects to data-controller="tabulator--lab-clinics"
export default class extends Controller {
  static values = {
    url: String,
    columns: Array,
    locale: String,
    confirmDeleteMessage: String,
    editUrlTemplate: String,
    deleteUrlTemplate: String,
  };

  connect() {
    const columns = this.prepareColumns(this.columnsValue);
    this.tabulator = new Tabulator(this.element, this.tabulatorOptions(columns));
  }

  prepareColumns(columns) {
    columns.push(this.actionsColumn());
    return columns;
  }

  tabulatorOptions(columns) {
    return {
      ajaxURL: this.urlValue,
      ajaxConfig: "GET",
      layout: "fitColumns",
      pagination: true,
      paginationMode: 'local',
      paginationSize: 15,
      paginationSizeSelector: [15, 25, 50, 100],
      sortMode: 'local',
      columns: columns,
      locale: this.localeValue,
      dataLoaderLoading: "<div class='tabulator-spinner'></div>",
      paginationCounter: "pageCounter",
    };
  }

  actionsColumn() {
    return {
      title: "",
      field: "actions",
      formatter: this.actionsFormatter.bind(this),
      cellClick: this.handleCellClick.bind(this),
      width: 150,
      headerSort: false,
    };
  }

  actionsFormatter(cell) {
    const row = cell.getRow();
    const data = row.getData();
    const editUrl = this.editUrl(data);
    const deleteUrl = this.deleteUrl(data);
    const turboSetting = this.editTurboSetting(data);

    let turboAttr = "";
    if (turboSetting === false) {
      turboAttr = 'data-turbo="false"';
    } else if (turboSetting) {
      turboAttr = `data-turbo-frame="${turboSetting}"`;
    }

    return `
    <div class='flex items-center space-x-1' data-row-id='${data.id}'>
      <a href="${editUrl}" class="edit-button" ${turboAttr}>${editSvg}</a>
      <button class="delete-button" 
              data-delete-url="${deleteUrl}" 
              data-row-id="${data.id}" 
              data-confirm="${this.confirmDeleteMessageValue}">${deleteSvg}</button>
      </div>
    `;
  }

  handleCellClick(e, cell) {
    const cellElement = cell.getElement();
    const deleteButton = cellElement.querySelector('.delete-button');
    
    if (deleteButton && (e.target === deleteButton || deleteButton.contains(e.target))) {
      handleDeleteClick(deleteButton.dataset, this.tabulator);
    }
  }

  editUrl(data) {
    return this.editUrlTemplateValue.replace(":id", data.id);
  }

  deleteUrl(data) {
    return this.deleteUrlTemplateValue.replace(":id", data.id);
  }

  editTurboSetting() {
    return "edit_clinic";
  }

  disconnect() {
    if (this.tabulator) {
      this.tabulator.destroy();
      this.tabulator = null;
    }
  }
}
