// Custom action updates the targeted input disabled state
// https://turbo.hotwired.dev/reference/streams#update
Turbo.StreamActions.update_input_disabled = function () {
  this.targetElements.forEach((target) => {
    let isDisabled = this.templateContent.textContent.trim().toLowerCase() === 'true';
    if (isDisabled) {
      target.setAttribute('disabled', '');
    } else {
      target.removeAttribute('disabled');
    }
  });
};