import {Controller} from "@hotwired/stimulus"
import {sendTurboStreamRequest} from "../helpers/turbo_stream_helper"
import {buildHeaders, buildRequest, buildUrl} from "../helpers/build_request"
import { downloadPdf } from "../helpers/download_pdf";

// Connects to data-controller="device-card"
export default class extends Controller {
    static targets = [
        "brandIdInput",
        "deviceModelIdInput",
        "hourlyCapacityContainer",
        "hourlyCapacityValue",
        'pdfButton',
        'lab',
        'consumptionPoint',
        'labGroup',
        'testGroup',
        'supplier',
        'technicalServiceManager',
        'applicationSupervisor'
    ]

    connect() {
        super.connect();
        setTimeout(() => {
            if (this.deviceModelIdInputTarget.tomselect && this.brandIdInputTarget.tomselect.getValue() == '') {
                this.deviceModelIdInputTarget.tomselect.disable();
            }
           this.labTarget.dispatchEvent(new Event('change'));
           this.testGroupTarget.dispatchEvent(new Event('change'));
           this.disableLabAndConsumptionSelectOfExistingCard();
           
           this.supplierTarget.addEventListener('change', this.handleSupplierChange.bind(this));
    
           if (this.supplierTarget.value) {
            this.handleSupplierChange();
          }
        }, 0);
    }

    disableLabAndConsumptionSelectOfExistingCard() {
        if(!JSON.parse(this.labTarget.dataset.newRecord))
        {
            this.labTarget.tomselect.wrapper.classList.add('ts-bg-zinc-100', 'pointer-events-none');
            this.consumptionPointTarget.tomselect.wrapper.classList.add('ts-bg-zinc-100', 'pointer-events-none');
        }
    }

    handleSupplierChange() {
      setTimeout(() => {
        this.setInitialValues();
      }, 0); 
    }
  
    setInitialValues() {
      const technicalServiceManagerValues = JSON.parse(this.technicalServiceManagerTarget.dataset.initialValues || '[]');
      const applicationSupervisorValues = JSON.parse(this.applicationSupervisorTarget.dataset.initialValues || '[]');
    
      if (this.technicalServiceManagerTarget.tomselect) {
          this.technicalServiceManagerTarget.tomselect.setValue(technicalServiceManagerValues, true);
        }
        if (this.applicationSupervisorTarget.tomselect) {
          this.applicationSupervisorTarget.tomselect.setValue(applicationSupervisorValues, true);
        }
    }

    getBrandDeviceModels() {
        var selectedBrandId = this.brandIdInputTarget.tomselect.getValue();
        var deviceModelInput = this.deviceModelIdInputTarget.tomselect;
        if (selectedBrandId == '') {
            deviceModelInput.clear();
            deviceModelInput.clearOptions();
            deviceModelInput.disable();
        } else {
            deviceModelInput.enable();
            fetch("/brand_device_models?brand_id=" + selectedBrandId)
                .then(response => response.json())
                .then(data => this.updateDeviceModelInput(deviceModelInput, data))
        }
    }

    updateDeviceModelInput(deviceModelInput, deviceModels) {
        deviceModelInput.clear();
        deviceModelInput.clearOptions();
        deviceModelInput.addOptions(deviceModels);
        deviceModelInput.refreshOptions();
        deviceModelInput.refreshItems();
        deviceModelInput.sync();
    }

    getDeviceModelHourlyCapacity() {
        var deviceModelInput = this.deviceModelIdInputTarget.tomselect.getValue();
        if (deviceModelInput) {
            fetch("/device_model_hourly_capacity?model_id=" + deviceModelInput)
                .then(response => response.json())
                .then(data => this.updateDeviceModelHourlyCapacity(data));
        }
    }

    updateDeviceModelHourlyCapacity(data) {
        this.hourlyCapacityValueTarget.value = data["hourly_capacity"];
    }

    // downloand pdf
    pdfButtonTargetConnected() {
        this.pdfButtonTarget.addEventListener('click',(event) => downloadPdf(event));
    }

    updateConsumptionPointState() {
      let consumptionPoint = this.consumptionPointTarget.tomselect;
      if (this.labTarget.value === '') {
        consumptionPoint.disable();
      }
      else {
        consumptionPoint.enable();
      }
    }

    updateStateOfLabGroup() {
      const labGroup = this.labGroupTarget.tomselect;
      if (this.testGroupTarget.value === '') {
        labGroup.enable();
      }
      else {
        labGroup.disable();
      }
    }

    turboStreamRequestForLabGroups(event) {
      const selectBox = event.target
      const turboParams = JSON.parse(selectBox.dataset.turboParams);
      const params = { id: selectBox.value };
      const url = buildUrl(turboParams.url, params);
      const request = buildRequest(url, buildHeaders());
      sendTurboStreamRequest(request);
    }
}
