export const editSvg = `<svg width="19" height="19" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2 20.525C1.45 20.525 0.979 20.329 0.587 19.937C0.195667 19.5457 0 19.075 0 18.525V4.525C0 3.975 0.195667 3.504 0.587 3.112C0.979 2.72067 1.45 2.525 2 2.525H10.925L8.925 4.525H2V18.525H16V11.575L18 9.575V18.525C18 19.075 17.8043 19.5457 17.413 19.937C17.021 20.329 16.55 20.525 16 20.525H2ZM13.175 3.1L14.6 4.5L8 11.1V12.525H9.4L16.025 5.9L17.45 7.3L10.25 14.525H6V10.275L13.175 3.1ZM17.45 7.3L13.175 3.1L15.675 0.6C16.075 0.2 16.5543 0 17.113 0C17.671 0 18.1417 0.2 18.525 0.6L19.925 2.025C20.3083 2.40833 20.5 2.875 20.5 3.425C20.5 3.975 20.3083 4.44167 19.925 4.825L17.45 7.3Z" fill="#f6c513"/>
                        </svg>`;

export const deleteSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="18" height="18">
                            <path d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z" fill="#F01F51"/>
                          </svg>`;

export const warningExclamationSvg = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                                      </svg>`;

export const redUpArrowSvg = `<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-error" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 11l5-5m0 0l5 5m-5-5v12"></path>
                              </svg>`;

export const redDownArrowSvg = `<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-error" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 13l-5 5m0 0l-5-5m5 5V6"></path>
                                </svg>`;

export const greenLeftArrowSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 254000 254000" data-icon="green_left_arrow" clip-rule="evenodd" fill-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
                                    <g id="&#x56FE;&#x5C42;_x0020_1"><path d="m0 127000c0-70129 56871-127000 127000-127000s127000 56871 127000 127000-56871 127000-127000 127000-127000-56871-127000-127000zm128068 42056-29172-29172h94964c3692 0 6773-3081 6773-6773v-12222c0-3692-3043-6773-6773-6773h-94964l29172-29171c2637-2638 2637-6941 0-9579l-8642-8642c-2638-2637-6973-2606-9579 0l-54525 54525c-1543 1543-2172 3704-1887 5751-285 2047 344 4209 1887 5752l54525 54525c2606 2606 6941 2637 9579 0l8642-8642c2637-2638 2637-6941 0-9579z" fill="#48b02c"/></g>
                                  </svg>`;

export const redRightArrowSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.54 2.54" data-icon="red_right_arrow" clip-rule="evenodd" fill-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision"><g id="&#x56FE;&#x5C42;_x0020_1"><circle fill="#ff4141" r="1.252" transform="matrix(0 -1.015 -1.015 0 1.27 1.27)"/><g id="_299929640" fill="#fff">
                                    <path id="_299932136" d="m1.86726 1.14116h-1.26586c-.0373 0-.06773.03081-.06773.06773v.12222c0 .03692.03081.06773.06773.06773h1.26586c.03692 0 .06773-.03043.06773-.06773v-.12222c0-.0373-.03043-.06773-.06773-.06773z"/><g>
                                    <path id="_299932160" d="m1.25932.84945.54525.54525c.02638.02637.06973.02606.09579 0l.08642-.08642c.02605-.02606.02605-.06973 0-.09579l-.54525-.54525c-.02606-.02606-.06941-.02637-.09579 0l-.08642.08642c-.02637.02638-.02637.06941 0 .09579z"/>
                                    <path id="_299932328" d="m1.25932 1.69056.54525-.54525c.02638-.02637.06973-.02606.09579 0l.08642.08642c.02605.02606.02605.06973 0 .09579l-.54525.54525c-.02606.02606-.06941.02637-.09579 0l-.08642-.08642c-.02637-.02638-.02637-.06941 0-.09579z"/></g></g></g>
                                 </svg>`;
export const searchSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-4.5 w-4.5 transition-colors duration-200" fill="#a3a3b3">
                            <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z"/>
                          </svg>`;