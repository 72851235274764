import { Controller } from "stimulus";
import { downloadPdf } from "../helpers/download_pdf";

// Connects to data-controller="support-request"
export default class extends Controller {
  static targets = [
    "requestTypeInput",
    "supplierInput",
    "userInput",
    'pdfButton'
  ];

  //TODO: why we defined get method for targets?
  get requestType() {
    if (this.hasRequestTypeInputTarget) {
      return this.requestTypeInputTarget.value;
    }
  }

  get supplierInput() {
    if (this.hasSupplierInputTarget) {
      return this.supplierInputTarget;
    }
  }

  get userInput() {
    if (this.hasUserInputTarget) {
      return this.userInputTarget;
    }
  }

  get fetchUrl() {
    //TODO: using string interpolation for url is not good practice
    return `/support_requests/users?company_id=${this.element.dataset.companyId}`;
  }

  get supplierId() {
    if (this.hasSupplierInputTarget) {
      return this.supplierInput.tomselect.getValue();
    }
  }

  connect() {
    if (this.hasUserInputTarget) {
      if (typeof this.userInputTarget.tomselect === 'undefined') { 
        // wait for select-controller.js load
        setTimeout(() => {
          this.connect();
        }, 100); 
      } 
      else {
        this.updateForm();
      }
    }
  }

  updateForm() {
    this.initialize();
    
    this.clear();

    let fetchUrl = this.fetchUrl;

    if (this.requestType === 'supplier') {
      if (this.supplierId !== '') {
        //TODO: using string interpolation for url is not good practice
        fetchUrl += `&supplier_id=${this.supplierId }`;
        this.fetchUsers(fetchUrl);
      }
    }
    if (this.requestType === 'employee') {
      this.fetchUsers(fetchUrl);
    }
  }

  clear() {
    this.userInput.tomselect.clear();
    this.userInput.tomselect.clearOptions();
    this.userInput.innerHTML = '';
  }

  fetchUsers(url) {
    fetch(url)
      .then(response => response.json())
      .then(data => {
        this.updateInput(data.users);
        this.appendCurrentValue();
      });
  }

  updateInput(users) {
    this.userInput.tomselect.clear();
    this.userInput.tomselect.clearOptions();
    this.clear();
    this.userInput.tomselect.addOptions(users);
    this.userInput.tomselect.refreshItems();
    this.userInput.tomselect.sync();
  }

  appendCurrentValue() {
    if (this.userInput.dataset.userId !== '') {
      this.userInput.tomselect.setValue(this.userInput.dataset.userId); 
    }
  }

  // downloand pdf
  pdfButtonTargetConnected() {
    this.pdfButtonTarget.addEventListener('click',(event) => downloadPdf(event));
  }
}
