import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="warehouse"
export default class extends Controller {
  static targets = ["mainWarehouse", "mainWarehouseElem", "authorityElem", "warehouse", "stockCard", "supervisorUsers"]

  static values = {
    type: String,
  };

  connect(){
    if ((this.hasWarehouseTarget && typeof this.warehouseTarget.tomselect === 'undefined') ||
        (this.hasMainWarehouseTarget && typeof this.mainWarehouseTarget.tomselect === 'undefined')) {
      setTimeout(() => {
        this.connect();
      }, 100);
    }
    else {
      if (this.typeValue == "stockMovement")
      {
        this.fetchWarehouseStockCards();
      }
      else
      {
        this.updateMainWarehouse();
      }
    }
  }

  updateMainWarehouse() {
    let mainWarehouseInput = this.mainWarehouseTarget.tomselect;
    let warehouseType = this.getSelectedType();

    if(warehouseType == "satellite") {
      this.mainWarehouseElemTarget.classList.remove('hidden');
      this.supervisorUsersTarget.tomselect.disable();
    }
    else {
      mainWarehouseInput.clear();
      this.mainWarehouseElemTarget.classList.add('hidden');
      this.supervisorUsersTarget.tomselect.enable();
    }
  }

  getSelectedType() {
    const selectedRadio = this.element.querySelector('input[name="warehouse[warehouse_type]"]:checked');
    return selectedRadio ? selectedRadio.value : null;
  }

  fetchWarehouseStockCards() {
    if (this.hasWarehouseTarget) {
      let warehouseId = this.warehouseTarget.value

      if (warehouseId && warehouseId != '') {
        fetch(`/warehouse_stock_cards?warehouse_id=${warehouseId}`)
            .then(response => response.json())
            .then(data => this.changeStockCardsOptions(data, this.stockCardTargets));
      }
      else {
        this.clearStockCardsOptions();
      }
    }
  }

  changeStockCardsOptions(data, stockCardTargets) {
    stockCardTargets.forEach(input => {
      if ((input.value === '')) {
        input.tomselect.clear();
        input.tomselect.clearOptions();
        input.tomselect.addOptions(data);
        input.tomselect.refreshItems();
        input.tomselect.setValue(input.dataset.selectedStockCardId);
      }
    });
  }

  clearStockCardsOptions() {
    this.stockCardTargets.forEach(input => {
        input.tomselect.clear();
        input.tomselect.clearOptions();
    });
  }
}
