import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  
  updateForm(event) {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.set('related_form_id', event.target.value);
    url.search = params.toString();
    window.location.href = url.toString();
  }

  redirectToSelected(event) {
    const selectedOption = event.target.selectedOptions[0];
    const url = selectedOption.getAttribute("data-url");
    if (url) {
      window.location.href = url;
    }
  }
}
