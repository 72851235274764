import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectAll", "checkbox", "countSelected", "toggleable", "selectAllWarehouses"];

  static values = {
    i18n: Object
  };

  get checkboxes() {
    return this.checkboxTargets;
  }

  get selectAll() {
    return this.selectAllTargets;
  }

  get selectedCheckboxes() {
    return this.checkboxes.filter((checkbox) => checkbox.checked).length;
  }

  connect() {
    this.insertSelectedCount();
    this.toggleToggleable();
    this.initializeSelectAllWarehouseCheckboxes();
  }

  initializeSelectAllWarehouseCheckboxes() {
    this.selectAllWarehousesTargets.forEach((selectAll) => {
      const warehouseId = selectAll.dataset.warehouseId;
      const relatedCheckboxes = this.checkboxes.filter(checkbox => checkbox.dataset.warehouseId === warehouseId);
      const allChecked = relatedCheckboxes.every(checkbox => checkbox.checked);
      selectAll.checked = allChecked;
    });
  }

  toggle(event) {
    const warehouseId = event.target.dataset.warehouseId;
    const isSelfToggle = event.target.dataset.selfToggle === "true";

    if (isSelfToggle) {
    } else if (warehouseId) {
      this.updateCheckboxesForWarehouse(warehouseId, event.target.checked);
    } else {
      this.updateStateForElements(this.checkboxes, event.target.checked);
    }
    this.updateSelectAllState();
    this.insertSelectedCount();
    this.toggleToggleable();
  }

  updateCheckboxesForWarehouse(warehouseId, isSelected) {
    this.checkboxes.forEach((checkbox) => {
      if (checkbox.dataset.warehouseId === warehouseId) {
        checkbox.checked = isSelected;
        if (isSelected) {
          checkbox.setAttribute("checked", "checked");
        } else {
          checkbox.removeAttribute("checked");
        }
      }
    });
  }

  updateSelectAllState() {
    this.insertSelectedCount();
    this.toggleToggleable();

    this.updateStateForElements(this.selectAll, this.checkboxes.every((checkbox) => checkbox.checked));
  }

  updateStateForElements(elements, isSelected) {
    elements.forEach((element) => {
      element.checked = isSelected;
      if (isSelected) {
        element.setAttribute("checked", "checked");
      } else {
        element.removeAttribute("checked");
      }
    });
  }

  insertSelectedCount() {
    if (this.hasCountSelectedTarget) {
      this.countSelectedTarget.innerHTML = `${this.selectedCheckboxes}`;
    }
  }

  toggleToggleable() {
    addEventListener("turbo:before-stream-render", ((event) => {
      if (this.hasToggleableTarget) {
        this.toggleableTarget.disabled = true;
      }
    }));

    if (this.hasToggleableTarget) {
      this.toggleableTarget.disabled = this.selectedCheckboxes == 0;
    }
  }
}
