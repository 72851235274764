import {Controller} from "@hotwired/stimulus"
import {buildUrl} from "../helpers/build_request";
// Connects to data-controller="qr-scanner"
export default class extends Controller {
  static targets = ["input", "overlay", "addNewRow", "stockEntry", "stockCardSelector", "checkAndIncrement",
    "initialAmount", "lotInfo", "expiryInfo", "scannerError", "scannerSuccess", "multipleLotInput",
    "lotSaver", "scanWithLot", "lotWithExpiry"];
  static values = {
    multipleLot: Boolean,
    closeUponScan: Boolean,
    addNewWhenNotFound: Boolean,
    singleCard: Boolean,
    redirectUrl: String,
  };

  connect() {
    this.clearAndToggleInput();
  }

  scan() {
    const barcodeString = this.inputTarget.value;

    const containsTurkishChars = /[şçğüöıİ]/.test(barcodeString);
    let deviceCardId;

    const isValidUrl = (string) => {
      try {
        new URL(string);
        return true;
      } catch (error) {
        if (error instanceof TypeError) {
          return false;
        } else {
          throw error;
        }
      }
    };

    if (containsTurkishChars) {
      const match = barcodeString.match(/devıce\?card\?ıd-([0-9]+)/);
      if (match && match[1]) {
        deviceCardId = match[1];
      }
    } else if (isValidUrl(barcodeString)) {
      const url = new URL(barcodeString);
      deviceCardId = url.searchParams.get("device_card_id");
    }

    if (deviceCardId) {
      this.redirectByDeviceId(deviceCardId);
      return;
    }

    // barcodeString does not contain wrong Turkish characters or a url
    if (!isValidUrl(barcodeString)) {
      const url = `/barcode_formats/scan?barcode=${barcodeString}`;
      fetch(url)
        .then(response => Promise.all([response.ok, response.json()]))
        .then(([ok, data]) => {
          // Check if the target exists first.
          if (ok) {
            if (this.singleCardValue) {
              this.singleScan(this, data);
            } else {
              this.multipleScan(this, data)
            }
            if (this.closeUponScanValue) {
              this.toggleOverlayAndAddEvents();
            }
          } else {
            this.showScanResult(false);
          }
        });

      this.inputTarget.value = '';
      this.inputTarget.focus();
    }
  }

  redirectByDeviceId(deviceCardId) {
    if (deviceCardId && this.redirectUrlValue) {
      const params = { device_card_id: deviceCardId };
      const url = buildUrl(this.redirectUrlValue, params);
      window.location.href = url;
    }
  }

  toggleOverlayAndAddEvents() {
    this.clearAndToggleInput();
    this.inputTarget.focus();
    this.overlayTarget.classList.toggle('hidden');
    this.addInputEvent();
  }

  findTargetIfExists(stockCardId, lot) {
    let stockEntry;
    stockEntry = this.stockEntryTargets.find(t => t.dataset && t.dataset.qrScannerStockCardId == stockCardId &&
      (lot == undefined || t.dataset.qrScannerLotNumber == lot));
    return stockEntry;
  }

  blockSubmit(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.scan();
    }
  }

  singleScan(dom, data) {
    if (dom.hasStockEntryTarget) {
      let lotAndExpiry = `${data.lot} - ${data.formatted_expiry_date}`;
      let selectBox = dom.stockEntryTarget.querySelector('[data-qr-scanner-target="stockCardSelector"]');
      selectBox.value = data.stock_card.id;
      selectBox.tomselect.setValue(data.stock_card.id);
      dom.checkAndIncrementTarget.value = Number(dom.checkAndIncrementTarget.value) + 1;
      this.singleScanSelectLotAndExpiry(dom, lotAndExpiry);
      this.showScanResult();
    }
  }

  singleScanSelectLotAndExpiry(dom, lotAndExpiryText) {
    var checkExist = setInterval(function () {
      if (dom.lotWithExpiryTarget.options.length > 0) {
        dom.lotWithExpiryTarget.tomselect.setValue(lotAndExpiryText);
        clearInterval(checkExist);
      }
    }, 100);
  }

  multipleScan(dom, data) {
    let existingTarget;
    if (dom.hasScanWithLotTarget) {
      existingTarget = dom.findTargetIfExists(data.stock_card.id, data.lot);
    } else {
      existingTarget = dom.findTargetIfExists(data.stock_card.id);
    }
    let stockEntry;
    if (!existingTarget) {
      stockEntry = dom.handleNonExistingStockCard(data, dom.multipleLotValue);
    } else {
      if (this.hasScanWithLotTarget) {
        stockEntry = document.querySelector(`tr[data-qr-scanner-stock-card-id="${data.stock_card.id}"][data-qr-scanner-lot-number="${data.lot}"]`);
      } else {
        stockEntry = document.querySelector(`tr[data-qr-scanner-stock-card-id="${data.stock_card.id}"]`);
      }
    }
    if (stockEntry && this.hasExpiryInfoTarget) {
      const expiryInformation = stockEntry.querySelector('[data-qr-scanner-target="expiryInfo"]');
      expiryInformation.value = data.expiry_date;
    }
    if (stockEntry) {
      let incrementChecked;
      if (this.hasCheckAndIncrementTarget) {
        incrementChecked = stockEntry.querySelector('[data-qr-scanner-target="checkAndIncrement"]');
      } else {
        incrementChecked = document.querySelector(`[data-qr-scanner-lot="${data.lot}"][data-qr-scanner-expire-date="${data.expiry_date}"]`);
      }
      if (!this.multipleLotValue) {
        incrementChecked.value = Number(incrementChecked.value) + 1;
        incrementChecked.dispatchEvent(new Event('input'));
      }
    }
    this.pickLot(stockEntry, data, this.multipleLotValue);
    this.showScanResult(stockEntry != undefined);
  }

  /* Updates the lot information of the stock card in transfers. */
  pickLot(dom_element, data, multipleLot = false) {
    let lot;
    lot = data.lot.trim();
    if (multipleLot) {
      // Multiple lot fields exist.
      setTimeout(() => {
        let lotIncrement = dom_element.querySelector(`[data-qr-scanner-lot="${lot}"][data-qr-scanner-expire-date="${data.expiry_date}"]`);
        if (lotIncrement.value === '' || isNaN(lotIncrement.value)) {
          lotIncrement.value = 1;
        } else {
          lotIncrement.value = Number(lotIncrement.value) + 1;
        }
        let lotSaver = dom_element.querySelector(`[data-qr-scanner-target="lotSaver"]`);
        lotSaver.click();
      }, 400);

    } else {
      let lotInformation = document.querySelector('[data-qr-scanner-target="lotInfo"]');
      lotInformation.value = lot;
    }
  }

  handleNonExistingStockCard(data, multipleLot) {
    let stockEntry;
    if (this.addNewWhenNotFoundValue) {
      this.addNewRowTarget.click();
      // Fill the form:
      stockEntry = this.stockEntryTargets[0];
      const selectBox = stockEntry.querySelector('[data-qr-scanner-target="stockCardSelector"]');
      const initialAmount = stockEntry.querySelector('[data-qr-scanner-target="initialAmount"]');
      initialAmount.value = 0;
      stockEntry.dataset.qrScannerStockCardId = data.stock_card.id;
      stockEntry.dataset.qrScannerLotNumber = data.lot;
      selectBox.value = data.stock_card.id;
      selectBox.dispatchEvent(new Event('change'));
    }
    return stockEntry;
  }

  showScanResult(success = true) {
    if (success) {
      this.scannerSuccessTarget.classList.remove('hidden');
      this.scannerErrorTarget.classList.add('hidden');
    } else {
      this.scannerErrorTarget.classList.remove('hidden');
      this.scannerSuccessTarget.classList.add('hidden');
    }
  }

  addInputEvent() {
    this.inputTarget.addEventListener('focusout', this.handleFocusOut.bind(this));
  }

  handleFocusOut(event) {
    if (this.overlayTarget.classList.contains('hidden')) {
      this.inputTarget.removeEventListener('focusout', this.handleFocusOut);
    } else {
      event.preventDefault();
      this.inputTarget.focus();
    }
  }

  clearAndToggleInput() {
    if (this.hasInputTarget) {
      this.inputTarget.value = '';
      this.inputTarget.classList.toggle('hidden');
    }
  }
}
